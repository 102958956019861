<template>
  <div class="wrongParameter">
    <!-- {{ gradeDontpushQuestionNums }} -->
    <div class="cnt-box">
      <div class="cnt-list-box" style="width: 900px">
        <div class="title">详细参数设置</div>

        <!--多个参数和子列表-->
        <div v-if="getItem('analyse')" class="cs-box after">
          <div class="left-title">成绩分析模板</div>
          <div class="right-cnt">
            <el-checkbox-group v-model="subFromList.reportStructures">
              <template v-if="$route.query.type == 0">
                <!-- <el-checkbox label="100">成绩小题概况</el-checkbox> -->
                <el-checkbox label="101">成绩概况</el-checkbox>
                <el-checkbox label="102">同层分段</el-checkbox>
                <el-checkbox label="103">学情跟踪</el-checkbox>
                <el-checkbox label="104">重点关注</el-checkbox>
                <el-checkbox label="105">提分建议</el-checkbox>
                <el-checkbox label="106">考点跟踪</el-checkbox>
                <el-checkbox label="999">提分训练</el-checkbox>
              </template>
              <template v-if="$route.query.type == 1">
                <el-checkbox label="201">班级成绩概况</el-checkbox>
                <el-checkbox label="202">班级分数段分布</el-checkbox>
                <el-checkbox label="203">班级试卷分析</el-checkbox>
                <el-checkbox label="204">班级知识点得分率分析</el-checkbox>
                <el-checkbox label="205">班级小题讲评分析</el-checkbox>
                <el-checkbox label="999">变式题汇总</el-checkbox>
              </template>
              <template v-if="$route.query.type == 2">
                <el-checkbox label="301">年级成绩概况</el-checkbox>
                <el-checkbox label="302">年级分数段分布</el-checkbox>
                <el-checkbox label="303">年级试卷分析</el-checkbox>
                <el-checkbox label="304">年级知识点得分率分析</el-checkbox>
                <el-checkbox label="305">年级小题讲评分析</el-checkbox>
                <el-checkbox label="999">变式题汇总</el-checkbox>
              </template>
              <template v-if="$route.query.type == 3">
                <el-checkbox label="401">群体成绩概况</el-checkbox>
                <el-checkbox label="402">群体分数段分布</el-checkbox>
                <el-checkbox label="403">群体试卷分析</el-checkbox>
                <el-checkbox label="404">群体知识点得分率分析</el-checkbox>
                <el-checkbox label="405">群体小题讲评分析</el-checkbox>
                <el-checkbox label="999">变式题汇总</el-checkbox>
              </template>
            </el-checkbox-group>
          </div>
        </div>
        <div v-if="getItem('analysis')" class="cs-box after">
          <div class="left-title">答案设置</div>
          <div class="right-cnt">
            <!-- {{ getItem("guide") }} -->
            <el-checkbox-group v-model="subFromList.answerSetting">
              <template v-for="(v, i) in answerList">
                <el-checkbox
                  v-if="v.id == 3"
                  :key="i"
                  :disabled="getItem('guide')"
                  :label="v.id"
                  >{{ v.name }}</el-checkbox
                >
                <el-checkbox v-else :key="i" :label="v.id">{{
                  v.name
                }}</el-checkbox>
              </template>
            </el-checkbox-group>
          </div>
        </div>
        <!-- 年级不做题 -->
        <!--  classDontpushQuestion gradeDontpushQuestion -->
        <div v-if="getItem('gradeNecessary')" class="cs-box after">
          <div class="left-title">年级必做题设置</div>
          <div class="right-cnt">
            <el-radio v-model="gradeQuestions.type" label="1"
              >自动推送</el-radio
            >
            <el-radio v-model="gradeQuestions.type" label="2"
              >手动推送</el-radio
            >
            <!--手动推送选择试题的盒子-->
            <!-- {{ questionOrders }} -->
            <div v-if="gradeQuestions.type == '2'" class="exam-box">
              <div class="exam-list">
                <template v-for="(v, i) in questionOrders">
                  <div :key="i + 'box'">
                    <p>{{ v.examName }}</p>
                    <el-checkbox-group
                      v-model="gradeMustQuestionNums[i].valArr"
                    >
                      <template v-for="(m, n) in v.examQuestionOrders">
                        <el-checkbox :key="n + '题'" :label="m"
                          >{{ m }}题</el-checkbox
                        >
                      </template>
                    </el-checkbox-group>
                  </div>
                </template>
              </div>
              <div class="exam-list suc-box">
                <template v-for="(v, i) in gradeMustQuestionNums">
                  <div :key="i + 'box1'">
                    <p>{{ v.examName }}必做题</p>
                    <el-checkbox-group
                      v-model="gradeMustQuestionNums[i].valArr"
                    >
                      <template v-for="(m, n) in v.valArr">
                        <el-checkbox :key="n + '题'" :label="m"
                          >{{ m }}题</el-checkbox
                        >
                      </template>
                    </el-checkbox-group>
                  </div>
                </template>
              </div>
            </div>
            <div v-if="gradeQuestions.type == '1'">
              <div style="margin-bottom: 6px">
                <span>自动推送必做题数量 </span>
                <input
                  v-model="gradeQuestions.quantity"
                  type="text"
                  placeholder="空"
                  class="df-text"
                />
                <span> 题</span>
              </div>
              <div>
                推题顺序：<el-radio
                  v-model="gradeQuestions.pushQuestionOrder"
                  label="1"
                  >班级得分率从高到低</el-radio
                >
                <el-radio v-model="gradeQuestions.pushQuestionOrder" label="2"
                  >班级得分率从低到高</el-radio
                >
              </div>
              <div style="margin-bottom: 6px">
                推题设置：<span>班级得分率 </span>
                <input
                  v-model="gradeQuestions.scoringLow"
                  type="text"
                  placeholder="空"
                  class="df-text"
                />
                <span>%</span>
                <span style="margin-left: 12px"> - </span>
                <input
                  v-model="gradeQuestions.scoringHigh"
                  type="text"
                  placeholder="空"
                  class="df-text"
                />
                <span>%</span>
              </div>
            </div>

            <div>
              <span style="margin-right: 10px">插入位置</span>
              <el-radio v-model="gradeQuestions.position" label="1" disabled
                >个性题前</el-radio
              >
              <el-radio v-model="gradeQuestions.position" label="2" disabled
                >个性题后</el-radio
              >
              <el-radio
                v-model="gradeQuestions.position"
                label="3"
                disabled
                style="margin-right: 0"
              >
                个性题中
              </el-radio>
              <span v-if="gradeQuestions.position == '3'">第</span>
              <input
                v-if="gradeQuestions.position == '3'"
                v-model="gradeQuestions.positionNum"
                type="text"
                placeholder="空"
                class="df-text"
              />
              <span v-if="gradeQuestions.position == '3'"> 题</span>
            </div>
          </div>
        </div>
        <div v-if="getItem('gradeDontpushQuestion')" class="cs-box after">
          <div class="left-title" style="color: #f56c6c">年级不做题设置</div>
          <div class="right-cnt">
            <div class="exam-box">
              <div class="exam-list">
                <template v-for="(v, i) in questionOrders">
                  <div
                    v-if="gradeDontpushQuestionNums.length > 0"
                    :key="i + 'box'"
                  >
                    <p>{{ v.examName }}</p>

                    <el-checkbox-group
                      v-model="gradeDontpushQuestionNums[i].valArr"
                    >
                      <template v-for="(m, n) in v.examQuestionOrders">
                        <el-checkbox :key="n + '题'" :label="m"
                          >{{ m }}题</el-checkbox
                        >
                      </template>
                    </el-checkbox-group>
                  </div>
                </template>
              </div>
              <div class="exam-list suc-box">
                <template v-for="(v, i) in gradeDontpushQuestionNums">
                  <div
                    v-if="gradeDontpushQuestionNums.length > 0"
                    :key="i + 'box1'"
                  >
                    <p>{{ v.examName }}不做题</p>
                    <el-checkbox-group
                      v-model="gradeDontpushQuestionNums[i].valArr"
                    >
                      <template v-for="(m, n) in v.valArr">
                        <el-checkbox :key="n + '题'" :label="m"
                          >{{ m }}题</el-checkbox
                        >
                      </template>
                    </el-checkbox-group>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-if="getItem('classNecessary')" class="cs-box after">
          <div class="left-title">班级必做题设置</div>
          <div class="right-cnt">
            <el-radio v-model="classQuestions.type" label="1"
              >自动推送</el-radio
            >
            <el-radio v-model="classQuestions.type" label="2"
              >手动推送</el-radio
            >
            <!--手动推送选择试题的盒子-->
            <div v-if="classQuestions.type == '2'" class="exam-box">
              <div class="exam-list">
                <div class="class-item-box">
                  <p>班级列表</p>
                  <!-- {{ classMustQuestionNums }} -->
                  <el-radio-group
                    v-if="newKeys"
                    v-model="classRadio"
                    size="mini"
                  >
                    <el-radio
                      v-for="v in classMustQuestionNums"
                      :key="v.classNum"
                      :label="v.classNum"
                      >{{ v.classNum }}班</el-radio
                    >
                  </el-radio-group>
                </div>
                <template v-for="(v, i) in questionOrders">
                  <div
                    v-if="newKeys && classMustQuestionNums.length > 0"
                    :key="i + 'box'"
                  >
                    <p>{{ v.examName }}</p>
                    <el-checkbox-group
                      v-model="
                        classMustQuestionNums.filter(
                          (i) => i.classNum == classRadio
                        )[0].questionOrders[i].questionItem
                      "
                    >
                      <template v-for="(m, n) in v.examQuestionOrders">
                        <el-checkbox :key="n + '题'" :label="m">
                          {{ m }}题</el-checkbox
                        >
                      </template>
                    </el-checkbox-group>
                  </div>
                </template>
              </div>
              <div class="exam-list suc-box">
                <template v-for="(v, i) in classMustQuestionNums">
                  <div
                    v-if="
                      newKeys &&
                      v.questionOrders.length > 0 &&
                      classMustQuestionNums[i].questionOrders
                    "
                    :key="i + 'box1'"
                  >
                    <p>{{ v.classNum }}班必做题列表</p>
                    <!-- questionItem -->
                    <template
                      v-for="(val, index) in classMustQuestionNums[i]
                        .questionOrders"
                    >
                      <p
                        v-if="val.questionItem.length > 0"
                        :key="index + 'questionItemname'"
                        style="color: #999999"
                      >
                        {{ val.examName }}
                      </p>
                      <el-checkbox-group
                        :key="index + 'questionItem'"
                        v-model="val.questionItem"
                      >
                        <template v-for="(m, n) in val.questionItem">
                          <el-checkbox :key="n + '题'" :label="m"
                            >{{ m }}题</el-checkbox
                          >
                        </template>
                      </el-checkbox-group>
                    </template>
                  </div>
                </template>
              </div>
            </div>
            <template v-if="classQuestions.type == '1'">
              <div style="margin-bottom: 6px">
                <span>自动推送必做题数量 </span>
                <input
                  v-model="classQuestions.quantity"
                  type="text"
                  placeholder="空"
                  class="df-text"
                />
                <span> 题</span>
              </div>

              <div>
                推题顺序：<el-radio
                  v-model="classQuestions.pushQuestionOrder"
                  label="1"
                  >班级得分率从高到低</el-radio
                >
                <el-radio v-model="classQuestions.pushQuestionOrder" label="2"
                  >班级得分率从低到高</el-radio
                >
              </div>
              <div style="margin-bottom: 6px">
                推题设置：<span>班级得分率 </span>
                <input
                  v-model="classQuestions.scoringLow"
                  type="text"
                  placeholder="空"
                  class="df-text"
                />
                <span>%</span>
                <span style="margin-left: 12px"> - </span>
                <input
                  v-model="classQuestions.scoringHigh"
                  type="text"
                  placeholder="空"
                  class="df-text"
                />
                <span>%</span>
              </div>
            </template>
            <div>
              <span>插入位置：</span>
              <el-radio v-model="classQuestions.position" label="1" disabled
                >个性题前</el-radio
              >
              <el-radio v-model="classQuestions.position" label="2" disabled
                >个性题后</el-radio
              >
              <el-radio
                v-model="classQuestions.position"
                label="3"
                disabled
                style="margin-right: 0"
              >
                个性题中
              </el-radio>
              <span v-if="classQuestions.position == '3'">第</span>
              <input
                v-if="classQuestions.position == '3'"
                v-model="classQuestions.positionNum"
                type="text"
                placeholder="空"
                class="df-text"
              />
              <span v-if="classQuestions.position == '3'"> 题</span>
            </div>
          </div>
        </div>
        <div v-if="getItem('classDontpushQuestion')" class="cs-box after">
          <div class="left-title" style="color: #f56c6c">班级不做题设置</div>
          <div class="right-cnt">
            <!-- classDontRadio -->
            <!-- <el-radio v-model="classQuestions.type" label="1"
              >自动推送</el-radio
            >
            <el-radio v-model="classQuestions.type" label="2"
              >手动推送</el-radio
            > -->
            <!--手动推送选择试题的盒子-->
            <div class="exam-box">
              <div class="exam-list">
                <div class="class-item-box">
                  <p>班级列表</p>
                  <!-- {{ classMustQuestionNums }} -->
                  <el-radio-group
                    v-if="newKeys"
                    v-model="classDontRadio"
                    size="mini"
                  >
                    <el-radio
                      v-for="v in classDontpushQuestionNums"
                      :key="v.classNum"
                      :label="v.classNum"
                      >{{ v.classNum }}班</el-radio
                    >
                  </el-radio-group>
                </div>
                <template v-for="(v, i) in questionOrders">
                  <div
                    v-if="newKeys && classDontpushQuestionNums.length > 0"
                    :key="i + 'box'"
                  >
                    <p>{{ v.examName }}</p>
                    <el-checkbox-group
                      v-model="
                        classDontpushQuestionNums.filter(
                          (i) => i.classNum == classDontRadio
                        )[0].questionOrders[i].questionItem
                      "
                    >
                      <template v-for="(m, n) in v.examQuestionOrders">
                        <el-checkbox :key="n + '题'" :label="m">
                          {{ m }}题</el-checkbox
                        >
                      </template>
                    </el-checkbox-group>
                  </div>
                </template>
              </div>
              <div class="exam-list suc-box">
                <template v-for="(v, i) in classDontpushQuestionNums">
                  <div
                    v-if="
                      newKeys &&
                      v.questionOrders.length > 0 &&
                      classDontpushQuestionNums[i].questionOrders
                    "
                    :key="i + 'box1'"
                  >
                    <p>{{ v.classNum }}班不做题列表</p>
                    <!-- questionItem -->
                    <template
                      v-for="(val, index) in classDontpushQuestionNums[i]
                        .questionOrders"
                    >
                      <p
                        v-if="val.questionItem.length > 0"
                        :key="index + 'questionItemname'"
                        style="color: #999999"
                      >
                        {{ val.examName }}
                      </p>
                      <el-checkbox-group
                        :key="index + 'questionItem'"
                        v-model="val.questionItem"
                      >
                        <template v-for="(m, n) in val.questionItem">
                          <el-checkbox :key="n + '题'" :label="m"
                            >{{ m }}题</el-checkbox
                          >
                        </template>
                      </el-checkbox-group>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <!-- {{ regionListSuc }} -->
        <div v-if="getItem('answerArea')" class="cs-box after">
          <div class="left-title">作答区域的大小设置</div>
          <div class="right-cnt">
            <!--装列表的盒子-->
            <div class="region-box">
              <div class="region-left">
                <template v-for="(v, i) in regionListSuc">
                  <div :key="i" class="region-list">
                    <span>{{ v.name }}</span>
                    <input
                      v-model="v.val"
                      type="text"
                      placeholder="空"
                      class="df-text"
                    />
                    <span style="margin-right: 8px">行；</span>
                  </div>
                </template>
              </div>
              <!--<div>-->
              <!-- <i class="el-icon-plus" @click="dialogVisible = true"></i> -->
              <!--</div>-->
            </div>
          </div>
        </div>

        <div v-if="getItem('area')" class="cs-box after">
          <div class="left-title">签字区域的位置设置</div>
          <div class="right-cnt">
            <span>每隔</span>
            <input
              v-model="signatureText"
              type="text"
              placeholder="空"
              class="df-text"
            />
            <span style="margin-right: 12px">题插入签字区域、作答日期</span>
            <!-- <el-radio v-model="signature.type" label="1">横式</el-radio>
          <el-radio v-model="signature.type" label="2">竖式</el-radio> -->
          </div>
        </div>

        <div v-if="getItem('qrCode')" class="cs-box after">
          <div class="left-title">答案是否线上发布</div>
          <!-- <div class="right-cnt">
            <div>
              <el-radio v-model="resolve.type" label="1">是</el-radio>
              <el-radio v-model="resolve.type" label="2">否</el-radio>
            </div>
          </div> -->
          <div class="da-fb-box">
            <el-radio v-model="resolve.releaseType" label="1"
              >相对发布</el-radio
            >
            <el-radio v-model="resolve.releaseType" label="2"
              >绝对发布</el-radio
            >
            <template v-for="(v, i) in resolve.resolveTime">
              <div
                v-if="resolve.releaseType == 1"
                :key="i"
                class="da-list-time"
              >
                <template>
                  <input
                    v-model="v.min"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />
                  题至
                  <input
                    v-model="v.max"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />题
                </template>
                <span style="margin-left: 8px"
                  >生成报告后
                  <input
                    v-model.number="v.time"
                    style="width: 30px"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />
                  小时发布</span
                >
                <i
                  v-if="resolve.resolveTime.length != 1"
                  class="el-icon-delete"
                  style="margin-left: 20px"
                  @click="delTime(i, 1)"
                ></i>
                <i
                  v-if="i + 1 == resolve.resolveTime.length"
                  class="el-icon-plus"
                  style="margin-left: 20px"
                  @click="addTime(1)"
                ></i>
              </div>
            </template>
            <template v-for="(v, i) in resolve.absolutelyTime">
              <div
                v-if="resolve.releaseType == 2"
                :key="i"
                class="da-list-time"
              >
                <template>
                  <!-- <el-select
                  v-model="v.list"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  placeholder="请选择班级"
                >
                  <el-option
                    v-for="item in classListTwo"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select> -->
                  <input
                    v-model="v.min"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />
                  题至
                  <input
                    v-model="v.max"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />题
                </template>
                <span style="margin-left: 8px"
                  >发布时间
                  <el-date-picker
                    v-model="v.time"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择日期时间"
                  >
                  </el-date-picker
                ></span>
                <i
                  v-if="resolve.absolutelyTime.length != 1"
                  class="el-icon-delete"
                  style="margin-left: 20px"
                  @click="delTime(i, 2)"
                ></i>
                <i
                  v-if="i + 1 == resolve.absolutelyTime.length"
                  class="el-icon-plus"
                  style="margin-left: 20px"
                  @click="addTime(2)"
                ></i>
              </div>
            </template>
          </div>
        </div>
        <!--封面的盒子-->
        <div v-if="getItem('cover')" class="fm-box after">
          <div class="fm-item fm-title">
            <p>封面</p>
          </div>
          <!--第二个盒子-->
          <div
            class="fm-item add-img"
            :class="{ trueClass: subFromList.coverMap }"
          >
            <i v-if="!subFromList.coverMap" class="el-icon-plus"></i>
            <img class="img-style" :src="subFromList.coverMap" alt="" />
            <input
              ref="imgNode"
              type="file"
              accept="image/gif,image/jpeg,image/jpg,image/png"
              class="add-input"
              @change="changeImg($event, 'coverMap')"
            />
            <!-- <input type="file" class="file" @change="tirggerFile($event,'c')"> -->
          </div>
          <div class="fm-item">
            <el-dropdown class="el-menu-list">
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="setImg('onoCoverImg')"
                  >应用</el-dropdown-item
                >
                <!-- <el-dropdown-item @click.native="linkTo()">编辑</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
            <img class="img-style" :src="onoCoverImg" alt="" />
          </div>
          <div class="fm-item">
            <el-dropdown class="el-menu-list">
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="setImg('twoCoverImg')"
                  >应用</el-dropdown-item
                >
                <!-- <el-dropdown-item @click.native="linkTo()">编辑</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
            <img class="img-style" :src="twoCoverImg" alt="" />
          </div>
          <div class="fm-item">
            <el-dropdown class="el-menu-list">
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="setImg('threeCoverImg')"
                  >应用</el-dropdown-item
                >
                <!-- <el-dropdown-item @click.native="linkTo()">编辑</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
            <img class="img-style" :src="threeCoverImg" alt="" />
          </div>
        </div>
        <!--扉页-->
        <div v-if="getItem('endPaper')" class="fm-box after">
          <div class="fm-item fm-title">
            <p>扉页</p>
          </div>
          <!--第二个盒子-->
          <div class="fm-item add-img">
            <i class="el-icon-plus"></i>
          </div>
        </div>
        <!--封底的盒子-->
        <div v-if="getItem('backCover')" class="fm-box after">
          <div class="fm-item fm-title">
            <p>封底</p>
          </div>
          <!--第二个盒子-->
          <div class="fm-item add-img">
            <i class="el-icon-plus"></i>
          </div>
          <div class="fm-item">
            <el-dropdown class="el-menu-list">
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>应用</el-dropdown-item>
                <el-dropdown-item @click.native="linkTo()"
                  >编辑</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <img class="img-style" src="~@/assets/imgs/blockchain.png" alt="" />
          </div>
          <div class="fm-item">
            <el-dropdown class="el-menu-list">
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>应用</el-dropdown-item>
                <el-dropdown-item @click.native="linkTo()"
                  >编辑</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <img class="img-style" src="@/assets/imgs/remote.png" alt="" />
          </div>
          <div class="fm-item">
            <el-dropdown class="el-menu-list">
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>应用</el-dropdown-item>
                <el-dropdown-item @click.native="linkTo()"
                  >编辑</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <img class="img-style" src="@/assets/imgs/media.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { subjectOptions } from "@/core/util/constdata";
import { getStore } from "@/core/util/store";
import { getExamQuestionTypeByLevelAndSubject } from "@/core/api/exam/exampaper";
import { postImg } from "@/core/api/analy/analyreportsubject";

export default {
  name: "",
  props: {
    wrongParameterData: Object,
    newQuestionOrders: Array,
    classNums: Array,
    levelId: Number,
    titleName: String,
    subjectId: Number,
  },
  data() {
    return {
      allTitle: "",
      subjectOptions: subjectOptions,
      //编辑的时候组装好一个
      subEditData: {},
      // subjectId: "",
      title: getStore({ name: "title" }),
      answerList: [
        { name: "答案", id: 1 },
        { name: "分析", id: 2 },
        { name: "点评", id: 3 },
        { name: "解答", id: 4 },
      ],
      //三个默认封面的数据
      onoCoverImg:
        "https://static.wtjy.com/analy/report/cover/img/7394c7ba93344a57a3f32e5fd52433e7.jpg",
      twoCoverImg:
        "https://static.wtjy.com/anay/report/cover/img/18a8a2ab17b9491fa622c227400ac124.png",
      threeCoverImg:
        "https://static.wtjy.com/anay/report/cover/img/b7948ecb806a488eaae6d0a7c019a4fb.png",
      //弹出框
      upImgModel: false,
      upImgHeader: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
      fileList: [],
      dialogVisible: false,
      questionOrders: [],
      checkList: [],
      radio: "",
      //上一页保存的数据
      studentData: {},
      //封面
      // coverMap: "",
      subFromList: {
        //封面
        coverMap:
          "https://static.wtjy.com/analy/report/cover/img/7394c7ba93344a57a3f32e5fd52433e7.jpg",
        //扉页
        titlePage: "",
        //封底
        backCover: "",
        //成绩分析模板
        reportStructures: [],
        // 试题二维码
        QRCode: "1",
        // 错因
        wrongCause: "1",
        // 考点
        testSite: "1",
        // 回归教材
        backToTextbook: "",
        // 答案设置
        answerSetting: [1],
      },

      //这一页可能需要提交的数据
      // 年级必做题
      gradeQuestions: {
        //为1自动，为2手动
        type: "1",
        //自动推送的数量
        quantity: "1",
        // 个性题位置 1：前 2：后 3：中
        position: "",
        // 个性题为中的位置
        positionNum: "",
        // 推题顺序
        pushQuestionOrder: "1",
        // 班级得分率高于
        scoringHigh: 70,
        // 班级得分率低于
        scoringLow: 0,
      },
      // 年级不做题
      gradeDontpushQuestionNums: [],
      // 自定义的题列表
      gradeMustQuestionNums: [],
      //班级必做题
      classQuestions: {
        //为1自动，为2手动
        type: "1",
        quantity: "1",
        position: "",
        positionNum: "",
        // 推题顺序
        pushQuestionOrder: "1",
        // 班级得分率高于
        scoringHigh: 70,
        // 班级得分率低于
        scoringLow: 0,
      },
      // 班级题的列表
      classMustQuestionNums: [],
      // 班级不做题列表
      classDontpushQuestionNums: [],
      classDontRadio: Number,
      classRadio: Number,
      // 区域列表
      regionList: [],
      // 已选区域列表
      regionListSuc: [],
      // 已被选择的区域列表
      sucList: [],
      //签字区域设置
      signatureText: 3,
      resolve: {
        // 答案是否线上发布
        type: "1",
        //1:相对发布 2绝对发布
        releaseType: "1",
        //相对发布的列表
        resolveTime: [{ time: "1", min: 1, max: 3 }],
        //绝对发布的列表
        absolutelyTime: [{ time: "", min: 1, max: 3 }],
      },
      classListOne: [],
      classListTwo: [],
      tiemOption: [],
      newKeys: false,
      menuList: [
        {
          name: "学生报告",
          type: 0,
          isClick: true,
        },
        {
          name: "群体报告",
          type: 3,
          isClick: false,
        },
        {
          name: "班级报告",
          type: 1,
          isClick: false,
        },
        {
          name: "年级报告",
          type: 2,
          isClick: false,
        },
      ],
    };
  },
  watch: {
    questionOrders: {
      deep: true,
      handler() {
        this.setAllList();
      },
    },
    classListOne: {
      deep: true,
      handler() {
        this.setAllList();
      },
    },
    // gradeDontpushQuestionNums: {
    //   deep: true,
    //   handler() {
    //     console.log(this.gradeDontpushQuestionNums);
    //   },
    // },
  },
  created() {
    // console.log(this.newQuestionOrders);
    this.questionOrders = JSON.parse(JSON.stringify(this.newQuestionOrders));
    // console.log(this.questionOrders);
    this.getData();
    if (!this.$route.query.reportIdNum) {
      if (this.$route.query.type == 0) {
        this.subFromList.reportStructures = [
          "101",
          "102",
          "103",
          "104",
          "105",
          "106",
          "999",
        ];
      }
      if (this.$route.query.type == 1) {
        this.subFromList.reportStructures = [
          "201",
          "202",
          "203",
          "204",
          "205",
          "999",
        ];
      }
      if (this.$route.query.type == 2) {
        this.subFromList.reportStructures = [
          "301",
          "302",
          "303",
          "304",
          "305",
          "999",
        ];
      }
      if (this.$route.query.type == 3) {
        this.subFromList.reportStructures = [
          "401",
          "402",
          "403",
          "404",
          "405",
          "999",
        ];
      }
    }
    this.getSubjectList();
  },
  methods: {
    submit() {
      // console.log("进来了");
      // 提交之前判断数据进行组装
      // 拼接一个提交对象出来
      let submitData = {};
      if (this.getItem("cover")) {
        submitData.coverMap = this.subFromList.coverMap;
      }
      if (this.getItem("analyse")) {
        submitData.reportStructures = this.subFromList.reportStructures;
      }
      // if (this.getItem("试题")) {
      //   submitData.reportStructures = this.subFromList.reportStructures;
      // }
      // if (this.getItem("错因")) {
      //   submitData.wrongCause = this.subFromList.wrongCause;
      // }
      if (this.getItem("analysis")) {
        submitData.answerSetting = this.subFromList.answerSetting;
        // submitData.resolve = this.resolve;
      }
      // console.log(submitData.answerSetting);
      // if (this.getItem("考点")) {
      //   submitData.testSite = this.subFromList.testSite;
      // }
      if (this.getItem("gradeNecessary")) {
        submitData.gradeQuestions = this.gradeQuestions;
        submitData.gradeMustQuestionNums = this.gradeMustQuestionNums;
      } else {
        submitData.gradeQuestions = {};
        submitData.gradeMustQuestionNums = [];
      }
      if (this.getItem("classNecessary")) {
        submitData.classQuestions = this.classQuestions;
        submitData.classMustQuestionNums = this.classMustQuestionNums;
      } else {
        submitData.classQuestions = {};
        submitData.classMustQuestionNums = [];
      }
      // 班级不做题
      if (this.getItem("classDontpushQuestion")) {
        submitData.classDontpushQuestionNums = this.classDontpushQuestionNums;
      } else {
        submitData.classDontpushQuestionNums = [];
      }
      // 年级不做题
      if (this.getItem("gradeDontpushQuestion")) {
        submitData.gradeDontpushQuestionNums = this.gradeDontpushQuestionNums;
      } else {
        submitData.gradeDontpushQuestionNums = [];
      }
      // console.log(submitData);
      // if (submitData) {
      //   return;
      // }
      // classDontpushQuestionNums gradeDontpushQuestionNums
      // classDontpushQuestion gradeDontpushQuestion
      let typeResolve = false;
      let typeCross = false;
      let typeVal = 0;
      // debugger;
      let typeResolveVal = false;
      // let typeCrossVal =false
      if (this.getItem("qrCode")) {
        // if (this.resolve.type == 1) {
        if (this.resolve.releaseType == 1) {
          this.resolve.resolveTime.forEach((index) => {
            if (Number(index.min) > Number(index.max)) {
              typeResolve = true;
            }
            if (Number(index.min) > Number(typeVal)) {
              typeVal = index.max;
            } else {
              typeCross = true;
            }
            if (index.time == "") {
              typeResolveVal = true;
            }
          });
        } else {
          this.resolve.absolutelyTime.forEach((index) => {
            if (Number(index.min) > Number(index.max)) {
              typeResolve = true;
            }
            if (Number(index.min) > Number(typeVal)) {
              typeVal = index.max;
            } else {
              typeCross = true;
            }
            if (index.time == "") {
              typeResolveVal = true;
            }
          });
        }
        // } else {
        //   submitData.resolve = this.resolve;
        // }
      }
      //       resolve: {
      //   // 答案是否线上发布
      //   type: "2",
      //   //1:相对发布 2绝对发布
      //   releaseType: "1",
      //   //相对发布的列表
      //   resolveTime: [{ time: "1", min: 1, max: 3 }],
      //   //绝对发布的列表
      //   absolutelyTime: [{ time: "", min: 1, max: 3 }]
      // },
      if (typeResolveVal) {
        this.$message({
          message: "发布时间不能为空",
          type: "warning",
        });
        return;
      }
      if (typeCross) {
        this.$message({
          message: "题号存在交叉值",
          type: "warning",
        });
        return;
      }
      if (typeResolve) {
        this.$message({
          message: "题号最小值不能大于最大值",
          type: "warning",
        });
        return;
      }
      if (this.getItem("qrCode")) {
        submitData.resolve = this.resolve;
      }
      if (this.getItem("answerArea")) {
        submitData.regionListSuc = this.regionListSuc;
      }
      if (this.getItem("area")) {
        submitData.signatureText = this.signatureText;
      }
      // if (this.getItem("试题二维码")) {
      //   submitData.QRCode = this.subFromList.QRCode;
      // }

      this.$emit("setAllData", "wrongParameterData", submitData);
    },
    // 上传图片
    changeImg(e, type) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      let url = "analy-report-cover-img";
      postImg(url, formData).then((res) => {
        this.subFromList[type] = res.data.data.url;
        this.$refs.imgNode.value = null;
      });
    },
    addTime(type) {
      if (type == 1) {
        this.resolve.resolveTime.push({ time: "", min: "", max: "" });
      } else {
        this.resolve.absolutelyTime.push({ time: "", min: "", max: "" });
      }
    },
    delTime(i, type) {
      if (type == 1) {
        this.resolve.resolveTime.splice(i, 1);
      } else {
        this.resolve.absolutelyTime.splice(i, 1);
      }
    },
    setAllList() {
      if (
        this.classMustQuestionNums.length > 0 &&
        this.classListOne.length > 0
      ) {
        // console.log("触发了可");
        //判断被删除的班级，从以前的列表中删除
        let newArray = [];
        let newArraytype = [];
        this.classMustQuestionNums.forEach((index) => {
          if (this.classListOne.indexOf(index.classNum) != -1) {
            newArray.push(index);
            newArraytype.push(index.classNum);
          }
        });
        //判断新加的班级，重新添加进去
        let newClassList = [];
        this.classListOne.forEach((index) => {
          if (newArraytype.indexOf(index) == -1) {
            newClassList.push({
              classNum: index,
              questionOrders: new Array(),
            });
          }
        });
        this.questionOrders.forEach((i) => {
          newClassList.map((index) => {
            index.questionOrders.push({
              examName: i.examName,
              examPaperId: i.examPaperId,
              questionItem: new Array(),
            });
          });
        });
        newArray = newArray.concat(newClassList);
        // console.log(newArray);
        // console.log(newClassList);
        // console.log(this.classListOne);
        this.classMustQuestionNums = newArray;
        if (this.classMustQuestionNums.length > 0) {
          this.classRadio = this.classMustQuestionNums[0].classNum;
          this.classDontRadio = this.classMustQuestionNums[0].classNum;
        }
        // console.log(this.gradeDontpushQuestionNums);
        this.newKeys = true;
      }
    },
    getSubjectList() {
      let data = new URLSearchParams();
      data.append("level", this.levelId);
      data.append("subjectId", this.subjectId);

      getExamQuestionTypeByLevelAndSubject(data).then((res) => {
        // 判斷下  如果题型不对 就重新赋值
        if (this.regionListSuc && this.regionListSuc.length > 0) {
          if (this.regionListSuc[0].level != this.levelId) {
            this.subFromList.regionListSuc = [];
            this.regionListSuc = [];
          }
        }
        if (
          !this.subFromList.regionListSuc ||
          this.subFromList.regionListSuc.length == 0
        ) {
          res.data.data.resQuestionTypes.forEach((i) => {
            this.regionListSuc.push({
              id: i.id,
              level: i.level,
              subjectId: i.subjectId,
              name: i.name,
              val: 1,
            });
          });
        }
        // console.log(this.regionListSuc);
      });
    },
    getData() {
      this.subFromList = Object.assign({}, this.wrongParameterData);
      this.gradeQuestions = this.subFromList.gradeQuestions;
      this.classQuestions = this.subFromList.classQuestions;
      this.resolve = this.subFromList.resolve;
      this.regionListSuc = this.subFromList.regionListSuc;
      subjectOptions.forEach((i) => {
        if (i.value == this.subjectId) {
          this.allTitle = this.titleName + ` (${i.label})`;
        }
      });
      // console.log(this.subFromList.gradeMustQuestionNums);
      //存在就是编辑   //如果等于一，就清空赋值
      if (this.gradeQuestions.type == 1) {
        this.gradeMustQuestionNums = [];
      } else {
        this.gradeMustQuestionNums = this.subFromList.gradeMustQuestionNums;
        this.gradeDontpushQuestionNums =
          this.subFromList.gradeDontpushQuestionNums;
      }
      if (this.classQuestions.type == 1) {
        this.classMustQuestionNums = [];
      } else {
        this.classQuestions = this.subFromList.classQuestions;
        this.classMustQuestionNums = this.subFromList.classMustQuestionNums;
      }
      if (this.subFromList.classDontpushQuestionNums) {
        this.classDontpushQuestionNums =
          this.subFromList.classDontpushQuestionNums;
      }
      // console.log(this.gradeDontpushQuestionNums);
      if (this.subFromList.gradeDontpushQuestionNums) {
        this.gradeDontpushQuestionNums =
          this.subFromList.gradeDontpushQuestionNums;
      }
      //    // classDontpushQuestionNums gradeDontpushQuestionNums
      // classDontpushQuestion gradeDontpushQuestion
      // console.log(this.questionOrders);
      if (
        !this.$route.query.reportIdNum ||
        this.gradeQuestions.type != 2 ||
        this.gradeMustQuestionNums.length == 0
      ) {
        // console.log("22222222");
        this.questionOrders.map((i) => {
          i.examQuestionOrders = i.examQuestionOrders.split(";");
          // 年级必做题
          this.gradeMustQuestionNums.push({
            examName: i.examName,
            examPaperId: i.examPaperId,
            valArr: [],
          });
        });
      } else {
        this.questionOrders.map((i) => {
          if (typeof i.examQuestionOrders != "string") return;
          i.examQuestionOrders = i.examQuestionOrders.split(";");
        });
      }

      // 年级不做题添加
      // console.log(this.gradeMustQuestionNums);
      // console.log(this.questionOrders);
      let order_list = this.questionOrders.map((item) => item.examPaperId);
      this.gradeDontpushQuestionNums = this.gradeDontpushQuestionNums.filter(
        (item) => order_list.indexOf(item.examPaperId) != -1
      );
      if (
        !this.$route.query.reportIdNum ||
        this.gradeDontpushQuestionNums.length == 0
      ) {
        this.questionOrders.map((i) => {
          this.gradeDontpushQuestionNums.push({
            examName: i.examName,
            examPaperId: i.examPaperId,
            valArr: [],
          });
        });
      }

      // console.log();
      //存在reportIdNumh就是编辑
      // 班级必做题添加
      if (
        !this.$route.query.reportIdNum ||
        this.classQuestions.type != 2 ||
        this.classMustQuestionNums.length == 0
      ) {
        this.classNums.forEach((i) => {
          if (
            this.classMustQuestionNums.filter((item) => item.classNum == i)
              .length == 0
          ) {
            this.classMustQuestionNums.push({
              classNum: i,
              questionOrders: new Array(),
            });
          }

          this.tiemOption.push({
            classNum: i,
            questionOrders: new Array(),
          });
        });
        this.questionOrders.forEach((i) => {
          this.classMustQuestionNums.map((index) => {
            index.questionOrders.push({
              examName: i.examName,
              examPaperId: i.examPaperId,
              questionItem: new Array(),
            });
          });
        });
      }
      // 班级不做题添加
      if (
        !this.$route.query.reportIdNum ||
        this.classDontpushQuestionNums.length == 0
      ) {
        this.classNums.forEach((i) => {
          if (
            this.classDontpushQuestionNums.filter((item) => item.classNum == i)
              .length == 0
          ) {
            this.classDontpushQuestionNums.push({
              classNum: i,
              questionOrders: new Array(),
            });
          }

          // this.tiemOption.push({
          //   classNum: i,
          //   questionOrders: new Array(),
          // });
        });
        this.questionOrders.forEach((i) => {
          this.classDontpushQuestionNums.map((index) => {
            index.questionOrders.push({
              examName: i.examName,
              examPaperId: i.examPaperId,
              questionItem: new Array(),
            });
          });
        });
      }

      this.classListOne = this.classNums;
      this.classListTwo = this.classNums;
      // console.log(this.classMustQuestionNums);
      // console.log(this.questionOrders);
    },
    //判断是否显示
    getItem(val) {
      if (
        this.$parent.wrongOrderRangeData.theOriginalQuestionList.filter(
          (item) => item == val
        ).length > 0
      ) {
        return true;
      }
      if (
        this.$parent.wrongOrderRangeData.reportStructure.filter(
          (item) => item == val
        ).length > 0
      ) {
        return true;
      }
      if (
        this.$parent.wrongOrderRangeData.layeredExpandList.filter(
          (item) => item == val
        ).length > 0
      ) {
        return true;
      }

      if (
        this.$parent.wrongOrderRangeData.variantTrainingList.filter(
          (item) => item == val
        ).length > 0
      ) {
        return true;
      }
    },
  },
};
</script>
<style lang="scss">
.wrongParameter {
  .el-radio__input.is-checked .el-radio__inner {
    border-color: #2474ed;
    background: #2474ed;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: rgba(0, 0, 0, 0.85);
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #2474ed;
    background: #2474ed;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
<style lang="scss" scoped>
.wrongParameter {
  .cnt-box {
    padding: 64px;
    /*padding-left: 64px;*/
    .df-text {
      width: 30px;
      border-bottom: 1px solid #999999;
      text-align: center;
    }

    .qm-box {
      display: flex;
      margin-top: 16px;
    }

    .cs-box {
      padding: 10px;
      display: flex;
      .da-fb-box {
        margin-left: 12px;
        .da-list-time {
          margin-top: 8px;
        }
      }
      i {
        cursor: pointer;
      }

      .left-title {
        color: rgba(0, 0, 0, 0.5);
        width: 198px;
        flex-shrink: 0;
      }

      .right-cnt {
        .region-box {
          display: flex;
          /*flex-wrap: wrap;*/
          justify-content: space-between;
          width: 560px;
          align-items: center;
          i {
            cursor: pointer;
          }
          .region-left {
            width: 580px;
            display: flex;
            flex-wrap: wrap;
          }
        }
        .exam-box {
          display: flex;
          margin-bottom: 10px;
          /*justify-content: space-between;*/
          .exam-list {
            width: 352px;
            max-height: 440px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 16px;
            border-radius: 4px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 3px;
              height: 3px;
            }

            ///*<!--修改 滑块 -->*/
            &::-webkit-scrollbar-thumb {
              background-color: #2474ed;
            }
          }

          .suc-box {
            margin-left: 32px;
            border-style: dashed;
          }
        }
      }
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
      font-weight: bold;
    }

    .after:last-child {
      &:before {
        bottom: 0;
        content: "";
      }
    }

    .after {
      position: relative;

      &:after,
      &:before {
        position: absolute;
        width: 768px;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
        left: 0;
      }

      &:after {
        top: 0;
        content: "";
      }
    }

    .fm-box {
      display: flex;
      /*justify-content: space-between;*/
      padding: 10px 0 10px 16px;

      .add-img {
        cursor: pointer;

        .el-icon-plus {
          font-size: 14px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
        }
      }
      .add-img.trueClass {
        border-color: #2474ed;
      }

      .fm-item {
        width: 160px;
        height: 213px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        position: relative;
        margin-right: 32px;
        .add-input {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          opacity: 0;
          cursor: pointer;
        }

        .el-menu-list {
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
        }

        .img-style {
          width: 135px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
        }
      }

      .fm-title {
        border: none;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .title-box {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    padding: 16px 64px;
    align-items: center;

    .title {
      font-size: 24px;
      font-weight: bold;
    }

    .btn-box {
      .cli-btn {
        background-color: #2474ed;
        border-color: #2474ed;
      }

      .btn-text {
        color: #2474ed;
      }

      .el-icon--right {
        color: white;
      }
    }
  }
}
</style>
