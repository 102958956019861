<template>
  <div class="wrongQuantityRange">
    <div class="cnt-box">
      <div class="cnt-list-box">
        <div class="title">试题推送方式</div>
        <div class="list number-box">
          <div class="list-title">仅推送原题</div>
          <div class="number-cnt number-cnt1">
            <el-radio v-model="from.pushTheOriginalQuestion" label="1"
              >是</el-radio
            >
            <el-radio v-model="from.pushTheOriginalQuestion" label="2"
              >否</el-radio
            >
          </div>
        </div>
        <template v-if="from.pushTheOriginalQuestion == 1">
          <div class="list number-box">
            <div class="list-title">班级组</div>
            <div class="list-title list-title1">总题量</div>
            <div class="list-title list-title2">题型</div>
          </div>

          <template v-for="(v, i) in pushTheOriginalQuestionClassList">
            <div :key="i + 'pushTheOriginalQuestion'" class="list number-box">
              <div class="number-cnt bj-number-box">
                <div class="scol-cnt">
                  <p v-for="(m, n) in v.classList" :key="n">
                    {{ m }} 班
                    <template v-if="v.classList.length != n + 1">, </template>
                  </p>
                </div>
              </div>
              <div class="number-cnt number-cnt1">
                最少
                <input
                  v-model="v.min"
                  type="text"
                  placeholder="空"
                  class="df-text"
                />
                ， 最多
                <input
                  v-model="v.max"
                  type="text"
                  placeholder="空"
                  class="df-text"
                />
              </div>
              <div class="number-cnt number-cnt2">
                <div v-for="(m, n) in v.question" :key="n + 'subject'">
                  {{ m.name }}
                  最少
                  <input
                    v-model="m.val"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />
                  ， 最多
                  <input
                    v-model="m.max"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />。
                </div>
              </div>
              <div class="number-cnt number-cnt3">
                <i
                  class="el-icon-setting"
                  @click="editList(i, 'pushTheOriginalQuestionClassList')"
                ></i>
                <i
                  class="el-icon-delete"
                  @click="delClass(i, 'pushTheOriginalQuestionClassList')"
                ></i>
              </div>
            </div>
          </template>

          <div class="list" style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)">
            <div
              class="add-box"
              @click="toAdd('pushTheOriginalQuestionClassList')"
            >
              <i class="el-icon-plus"></i> 新增
            </div>
          </div>
        </template>
        <template v-else>
          <div class="title">变式题推题比列</div>
          <div class="list number-box">
            <div class="list-title">变式题推题比例</div>
            <div class="number-cnt number-cnt1">
              <el-radio v-model="from.proportion" label="0">无</el-radio>
              <el-radio v-model="from.proportion" label="1">1:1</el-radio>
              <el-radio v-model="from.proportion" label="2">1:2</el-radio>
              <el-radio v-model="from.proportion" label="3">1:3</el-radio>
              <el-radio v-model="from.proportion" label="4">1:4</el-radio>
              <el-radio v-model="from.proportion" label="5">1:5</el-radio>
            </div>
          </div>
          <div v-if="$route.query.type == 0" class="list number-box">
            <div class="list-title">班级组</div>
            <div class="list-title list-title1">总题量</div>
            <div class="list-title list-title2">题型</div>
          </div>
          <template v-if="$route.query.type == 0">
            <template v-for="(v, i) in classPushlist">
              <div :key="i" class="list number-box">
                <div class="number-cnt bj-number-box">
                  <div class="scol-cnt">
                    <p v-for="(m, n) in v.classList" :key="n">
                      {{ m }} 班
                      <template v-if="v.classList.length != n + 1">, </template>
                    </p>
                  </div>
                </div>
                <div class="number-cnt number-cnt1">
                  最少
                  <input
                    v-model="v.min"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />
                  ， 最多
                  <input
                    v-model="v.max"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />
                </div>
                <div class="number-cnt number-cnt2">
                  <div v-for="(m, n) in v.question" :key="n + 'subject'">
                    {{ m.name }}
                    最少
                    <input
                      v-model="m.val"
                      type="text"
                      placeholder="空"
                      class="df-text"
                    />
                    ， 最多
                    <input
                      v-model="m.max"
                      type="text"
                      placeholder="空"
                      class="df-text"
                    />。
                  </div>
                </div>
                <div class="number-cnt number-cnt3">
                  <i
                    class="el-icon-setting"
                    @click="editList(i, 'classPushlist')"
                  ></i>
                  <i
                    class="el-icon-delete"
                    @click="delClass(i, 'classPushlist')"
                  ></i>
                </div>
              </div>
            </template>
          </template>
          <div
            v-if="$route.query.type == 0"
            class="list"
            style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
          >
            <div class="add-box" @click="toAdd('classPushlist')">
              <i class="el-icon-plus"></i> 新增
            </div>
          </div>
          <div class="title">拓展题推题比列</div>
          <!-- 分层拓展题 -->
          <div class="list number-box">
            <div class="list-title">拓展题推题比例</div>
            <div class="number-cnt number-cnt1">
              <el-radio v-model="from.layeredExtensionQuestion" label="0"
                >无</el-radio
              >
              <el-radio v-model="from.layeredExtensionQuestion" label="1"
                >1:1</el-radio
              >
              <el-radio v-model="from.layeredExtensionQuestion" label="2"
                >1:2</el-radio
              >
              <el-radio v-model="from.layeredExtensionQuestion" label="3"
                >1:3</el-radio
              >
              <el-radio v-model="from.layeredExtensionQuestion" label="4"
                >1:4</el-radio
              >
              <el-radio v-model="from.layeredExtensionQuestion" label="5"
                >1:5</el-radio
              >
            </div>
          </div>
          <div class="list number-box" style="height: 111px">
            <div class="list-title">拓展训练层级设置</div>
            <div class="number-cnt number-cnt1" style="display: inline-block">
              <div>
                排名前
                <input
                  v-model="from.extensionRankingTest"
                  type="text"
                  class="df-text"
                />
                %的学生,推送
                <el-select
                  v-model="from.extensionRankingTestType"
                  multiple
                  collapse-tags
                  class="item-select"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(v, i) in layeredList"
                    :key="i"
                    :label="v.name"
                    :value="v.val"
                  >
                  </el-option>
                </el-select>
              </div>
              <div>
                排名后
                <input
                  v-model="from.extensionAfterRanking"
                  type="text"
                  class="df-text"
                />
                %的学生,推送
                <el-select
                  v-model="from.extensionAfterRankingType"
                  class="item-select"
                  placeholder="请选择"
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="(v, i) in layeredList"
                    :key="i"
                    :label="v.name"
                    :value="v.val"
                  >
                  </el-option>
                </el-select>
              </div>
              <div>
                其他学生,推送
                <el-select
                  v-model="from.extensioNotherRankingType"
                  class="item-select"
                  placeholder="请选择"
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="(v, i) in layeredList"
                    :key="i"
                    :label="v.name"
                    :value="v.val"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <!-- 分层班级组列表 -->
          <div v-if="$route.query.type == 0" class="list number-box">
            <div class="list-title">班级组</div>
            <div class="list-title list-title1">总题量</div>
            <div class="list-title list-title2">题型</div>
          </div>
          <template v-if="$route.query.type == 0">
            <template v-for="(v, i) in extensionQuestionList">
              <div :key="i + 'bj-number-box'" class="list number-box">
                <div class="number-cnt bj-number-box">
                  <div class="scol-cnt">
                    <p
                      v-for="(m, n) in v.classList"
                      :key="n + 'extensionQuestionList'"
                    >
                      {{ m }} 班
                      <template v-if="v.classList.length != n + 1">, </template>
                    </p>
                  </div>
                </div>
                <div class="number-cnt number-cnt1">
                  最少
                  <input
                    v-model="v.min"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />
                  ， 最多
                  <input
                    v-model="v.max"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />
                </div>
                <div class="number-cnt number-cnt2">
                  <div
                    v-for="(m, n) in v.question"
                    :key="n + 'subjectextensionQuestionList'"
                  >
                    {{ m.name }}
                    最少
                    <input
                      v-model="m.val"
                      type="text"
                      placeholder="空"
                      class="df-text"
                    />
                    ， 最多
                    <input
                      v-model="m.max"
                      type="text"
                      placeholder="空"
                      class="df-text"
                    />。
                  </div>
                </div>
                <div class="number-cnt number-cnt3">
                  <i
                    class="el-icon-setting"
                    @click="editList(i, 'extensionQuestionList')"
                  ></i>
                  <i
                    class="el-icon-delete"
                    @click="delClass(i, 'extensionQuestionList')"
                  ></i>
                </div>
              </div>
            </template>
          </template>
          <div v-if="$route.query.type == 0" class="list">
            <div class="add-box" @click="toAdd('extensionQuestionList')">
              <i class="el-icon-plus"></i> 新增
            </div>
          </div>
        </template>
      </div>
    </div>
    <!--班级列表-->
    <el-dialog title="请选择列表信息" :visible.sync="dialogVisible" width="30%">
      <div>
        <p>
          请选择班级列表
          <el-checkbox v-model="checkedClass" @change="setAllClass"
            >全选</el-checkbox
          >
        </p>
        <el-checkbox-group v-model="checkList">
          <el-checkbox v-for="(v, i) in classList" :key="i" :label="v">{{
            v + "班"
          }}</el-checkbox>
        </el-checkbox-group>
        <p>请选择题目列表</p>
        <el-checkbox-group v-model="subjectSuccListData">
          <el-checkbox
            v-for="(v, i) in subjectListData"
            :key="i"
            :label="v.id"
            >{{ v.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setStudent">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//提交的班级组装数组
const classPushlist = [
  {
    classList: [],
    min: "3",
    max: "3",
    //题目列表 题目name 题目id 题目val
    question: [],
  },
];
//拓展题班级列表
const extensionQuestionList = [
  {
    classList: [],
    min: "3",
    max: "3",
    //题目列表 题目name 题目id 题目val
    question: [],
  },
];
const layeredList = [
  {
    name: "较易拓展题",
    val: "3",
  },
  {
    name: "中等拓展题",
    val: "2",
  },
  {
    name: "较难拓展题",
    val: "1",
  },
];
export default {
  name: "WrongQuantityRange",
  props: {
    classList: Array,
    subjectListData: Array,
    wrongQuantityRangeData: Object,
  },
  data() {
    return {
      //学生报告选择弹出层 start
      dialogVisible: false,
      checkedClass: false,
      checkList: [],
      subjectSuccListData: [],
      // 学生报告班级选择弹出层 end
      classPushlist: classPushlist,
      extensionQuestionList: extensionQuestionList,
      pushTheOriginalQuestionClassList: [],
      layeredList: layeredList,
      from: {
        // // 变式训练题
        // proportion: "1",
        // // 分层拓展题推题比例
        // extensionQuestion: "",
        // //排名靠前
        // extensionRankingTest: 27,
        // //排名靠前推送题类型
        // extensionRankingTestType: "1",
        // //排名靠后
        // extensionAfterRanking: 27,
        // //排名靠后推送题类型
        // extensionAfterRankingType: "3",
        // // 其他学生推送类型
        // extensioNotherRankingType: "2",
      },
    };
  },
  created() {
    // this.classList=
    // console.log(this.classList);
    this.serData();
  },
  methods: {
    //提交
    submit() {
      // 班级判断
      let isType = false;
      let isNumType = false;
      let isNumAll = false;
      let numberType = false;
      if (this.from.pushTheOriginalQuestion == 1) {
        this.pushTheOriginalQuestionClassList.forEach((i) => {
          if (i.min == "" || i.max == "") {
            isType = true;
          }
          if (Number(i.min) > Number(i.max)) {
            isNumType = true;
          }
          let max = i.max;
          let numberAll = 0;

          i.question.forEach((index) => {
            numberAll = Number(index.val) + numberAll;
            if (index.max != "") {
              if (Number(index.max) < Number(index.val)) {
                numberType = true;
              }
            }
          });
          if (numberAll > max) {
            isNumAll = true;
          }
        });
      }

      if (isType) {
        this.$message({
          message: "原题请设置推送数量和范围",
          type: "warning",
        });
        return;
      }
      if (isNumType) {
        this.$message({
          message: "原题推题最小值不能大于最大值",
          type: "warning",
        });
        return;
      }
      if (numberType) {
        this.$message({
          message: "原题型最小值不能大于最大值",
          type: "warning",
        });
        return;
      }
      if (isNumAll) {
        this.$message({
          message: "原题题型最小数量不能大于总题量最大值",
          type: "warning",
        });
        return;
      }
      if (this.from.pushTheOriginalQuestion == 2) {
        this.classPushlist.forEach((i) => {
          if (i.min == "" || i.max == "") {
            isType = true;
          }
          if (Number(i.min) > Number(i.max)) {
            isNumType = true;
          }
          let max = i.max;
          let numberAll = 0;

          i.question.forEach((index) => {
            numberAll = Number(index.val) + numberAll;
            if (index.max != "") {
              if (Number(index.max) < Number(index.val)) {
                numberType = true;
              }
            }
          });
          if (numberAll > max) {
            isNumAll = true;
          }
        });
      }
      if (isType) {
        this.$message({
          message: "变式题请设置推送数量和范围",
          type: "warning",
        });
        return;
      }
      if (isNumType) {
        this.$message({
          message: "变式题推题最小值不能大于最大值",
          type: "warning",
        });
        return;
      }
      if (numberType) {
        this.$message({
          message: "变式题题型最小值不能大于最大值",
          type: "warning",
        });
        return;
      }
      if (isNumAll) {
        this.$message({
          message: "变式题题型最小数量不能大于总题量最大值",
          type: "warning",
        });
        return;
      }
      if (this.from.pushTheOriginalQuestion == 2) {
        this.extensionQuestionList.forEach((i) => {
          if (i.min == "" || i.max == "") {
            isType = true;
          }
          if (Number(i.min) > Number(i.max)) {
            isNumType = true;
          }
          let max = i.max;
          let numberAll = 0;

          i.question.forEach((index) => {
            numberAll = Number(index.val) + numberAll;
            if (index.max != "") {
              if (Number(index.max) < Number(index.val)) {
                numberType = true;
              }
            }
          });
          if (numberAll > max) {
            isNumAll = true;
          }
        });
      }
      if (isType) {
        this.$message({
          message: "拓展题请设置推送数量和范围",
          type: "warning",
        });
        return;
      }
      if (isNumType) {
        this.$message({
          message: "拓展题推题最小值不能大于最大值",
          type: "warning",
        });
        return;
      }
      if (numberType) {
        this.$message({
          message: "拓展题题型最小值不能大于最大值",
          type: "warning",
        });
        return;
      }
      if (isNumAll) {
        this.$message({
          message: "拓展题题型最小数量不能大于总题量最大值",
          type: "warning",
        });
        return;
      }
      //  from.extensionRankingTest from.extensionRankingTestType
      if (
        this.from.extensionRankingTest == "" ||
        this.from.extensionRankingTestType == ""
      ) {
        this.$message({
          message: "拓展训练层级设置不能为空",
          type: "warning",
        });
        return;
      }
      this.from.classPushlist = this.classPushlist;
      this.from.extensionQuestionList = this.extensionQuestionList;
      this.from.pushTheOriginalQuestionClassList =
        this.pushTheOriginalQuestionClassList;
      this.$emit("setAllData", "wrongQuantityRangeData", this.from);
    },
    serData() {
      this.from = Object.assign({}, this.wrongQuantityRangeData);
      this.classPushlist = this.from.classPushlist;
      this.extensionQuestionList = this.from.extensionQuestionList;
      this.pushTheOriginalQuestionClassList =
        this.from.pushTheOriginalQuestionClassList;
      // console.log(this.extensionQuestionList);
      // console.log(this.classPushlist);
    },

    //添加或者删除列表
    setStudent() {
      let sbjArr = [];
      this.subjectSuccListData.sort();
      this.checkList.sort();
      this.subjectSuccListData.forEach((i) => {
        this.subjectListData.forEach((index) => {
          if (i == index.id) {
            // console.log(index);
            sbjArr.push({
              name: index.name,
              val: "",
              max: "",
              id: index.id,
            });
          }
        });
      });
      if (this.checkList.length == 0) {
        this.$message({
          message: "至少选择一个班级",
          type: "warning",
        });
        return;
      }
      if (this.modelIndex == -1) {
        this[this.indexType].push({
          classList: this.checkList,
          min: "",
          max: "",
          question: sbjArr,
        });
      } else {
        this[this.indexType][this.modelIndex].classList = this.checkList;
        // 添加新增的對象
        sbjArr.map((index) => {
          this[this.indexType][this.modelIndex].question.forEach((i) => {
            if (i.id == index.id) {
              index.val = i.val;
              index.max = i.max;
            }
          });
        });
        this[this.indexType][this.modelIndex].question = sbjArr;
        this.modelIndex = -1;
      }
      this.subjectSuccListData = [];
      this.dialogVisible = false;
    },
    setAllClass() {
      // console.log(this.classList);
      // console.log(this.checkList);
      if (this.checkedClass) {
        this.checkList = this.classList;
      } else {
        this.checkList = [];
      }
    },
    delClass(i, key) {
      // //console.log(id);
      this.indexType = key;
      if (this[key].length == 1) {
        this.$message({
          message: "至少选择存在一条",
          type: "warning",
        });
        return;
      }
      this[key].splice(i, 1);
    },
    editList(val, key) {
      this.indexType = key;
      this.modelIndex = val;
      this.dialogVisible = true;
      this.checkList = this[key][val].classList;
      let arr = [];
      this[key][val].question.forEach((i) => {
        arr.push(i.id);
      });
      this.subjectSuccListData = arr;
    },
    toAdd(key) {
      this.modelIndex = -1;
      this.indexType = key;
      this.subjectSuccListData = [];
      this.dialogVisible = true;
      this.checkList = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.wrongQuantityRange {
  .item-select {
    width: 204px;
  }
  .number-box {
    .number-cnt {
      > div {
        display: flex;
        align-items: center;
      }
    }
  }

  // width: 1220px;
  // margin: 0 auto;
}
</style>
