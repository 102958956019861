<template>
  <div class="wrongOrder">
    <div class="cnt-box">
      <div class="cnt-list-box">
        <div class="title">设置顺序、结构和样式</div>
        <div class="parameters-list">
          <div class="parameters-list-title">封面标题</div>
          <div class="parameters-list-cnt">
            <template>
              <el-radio v-model="from.coverTitleOption" label="1"
                >默认</el-radio
              >
              <el-radio v-model="from.coverTitleOption" label="2"
                >自定义</el-radio
              >
            </template>
            <input
              v-if="from.coverTitleOption == 2"
              v-model="from.coverTitle"
              type="text"
              class="df-text"
              style="width: 300px"
              maxlength="20"
              placeholder="长度<20个字"
              show-word-limit
            />
          </div>
        </div>
        <div class="parameters-list">
          <div class="parameters-list-title">考试名称是否显示</div>
          <div class="parameters-list-cnt">
            <template>
              <el-radio v-model="from.isShowExamName" label="1">是</el-radio>
              <el-radio v-model="from.isShowExamName" label="2">否</el-radio>
            </template>
          </div>
        </div>
        <div class="parameters-list">
          <div class="parameters-list-title">考试日期是否显示</div>
          <div class="parameters-list-cnt" style="display: flex">
            <template>
              <el-radio v-model="from.isShowExamDate" label="1">是</el-radio>
              <el-radio v-model="from.isShowExamDate" label="2">否</el-radio>
              <template v-if="from.isShowExamDate == 1">
                <el-radio v-model="from.examDateOptions" label="1">
                  默认
                </el-radio>
                <el-radio v-model="from.examDateOptions" label="2">
                  自定义
                </el-radio>
              </template>
            </template>
            <input
              v-if="from.examDateOptions == 2"
              v-model="from.examDate"
              type="date"
              class="df-text"
              style="width: 230px; margin-bottom: 0.5rem"
              maxlength="20"
              placeholder="长度<20个字"
              show-word-limit
            />
          </div>
        </div>
        <div class="parameters-list">
          <div class="parameters-list-title">原题是否显示</div>
          <div class="parameters-list-cnt">
            <el-radio v-model="from.isShowOriginal" label="1">是</el-radio>
            <el-radio v-model="from.isShowOriginal" label="2">否</el-radio>
          </div>
        </div>
        <template v-if="$route.query.type == 0">
          <div class="parameters-list">
            <div class="parameters-list-title">是否显示小题分</div>
            <div class="parameters-list-cnt">
              <template>
                <el-radio v-model="from.smallQuestionScore" label="1"
                  >是</el-radio
                >
                <el-radio v-model="from.smallQuestionScore" label="2"
                  >否</el-radio
                >
              </template>
            </div>
          </div>
          <div class="parameters-list">
            <div class="parameters-list-title">是否显示学生名字</div>
            <div class="parameters-list-cnt">
              <el-radio v-model="from.showStudentName" label="1">是</el-radio>
              <el-radio v-model="from.showStudentName" label="2">否</el-radio>
            </div>
          </div>
          <div class="parameters-list">
            <div class="parameters-list-title">是否显示学生班级</div>
            <div class="parameters-list-cnt">
              <el-radio v-model="from.showStudentClass" label="1">是</el-radio>
              <el-radio v-model="from.showStudentClass" label="2">否</el-radio>
            </div>
          </div>
          <div class="parameters-list">
            <div class="parameters-list-title">是否显示学生小题成绩</div>
            <div class="parameters-list-cnt">
              <el-radio v-model="from.showStudentQuestionScore" label="1">
                是
              </el-radio>
              <el-radio v-model="from.showStudentQuestionScore" label="2">
                否
              </el-radio>
            </div>
          </div>
          <div class="parameters-list">
            <div class="parameters-list-title">是否显示学生成绩概况</div>
            <div class="parameters-list-cnt">
              <el-radio
                v-model="from.showStudentAnswerGeneralSituation"
                label="1"
              >
                是
              </el-radio>
              <el-radio
                v-model="from.showStudentAnswerGeneralSituation"
                label="2"
              >
                否
              </el-radio>
            </div>
          </div>
          <div class="parameters-list">
            <div class="parameters-list-title">答案字号</div>
            <div class="parameters-list-cnt">
              <input
                v-model="from.AnswerSize"
                type="text"
                placeholder="例：15"
                class="df-text"
                style="width: 100px"
                oninput="value=value.replace(/[^0-9]/g,'')"
                @blur="from.AnswerSize = $event.target.value"
              />
            </div>
          </div>
          <div class="parameters-list">
            <div class="parameters-list-title">答案行距</div>
            <div class="parameters-list-cnt">
              <input
                v-model="from.AnswerSpacing"
                type="text"
                placeholder="例：15"
                class="df-text"
                style="width: 100px"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                @blur="from.AnswerSpacing = $event.target.value"
              />
            </div>
          </div>
          <div class="parameters-list">
            <div class="parameters-list-title">变式题行距</div>
            <div class="parameters-list-cnt">
              <input
                v-model="from.VariableLineSpacing"
                type="text"
                placeholder="例：15"
                class="df-text"
                style="width: 100px"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                @blur="from.VariableLineSpacing = $event.target.value"
              />
            </div>
          </div>
          <!-- AnswerSize -->
        </template>
        <div class="parameters-list">
          <div class="parameters-list-title">错题顺序</div>
          <div class="parameters-list-cnt">
            <el-radio v-model="from.wrongPriority" label="0">默认 </el-radio>
            <el-radio v-model="from.wrongPriority" label="1">
              题号优先
            </el-radio>
            <el-radio v-model="from.wrongPriority" label="2">
              简单题优先
            </el-radio>
          </div>
        </div>
        <div
          v-if="reportType == 0 && from.wrongPriority == 1"
          class="parameters-list"
        >
          <div class="parameters-list-title">项目顺序</div>
          <div class="parameters-list-cnt">
            <template>
              <el-radio v-model="from.OrderOfItems" label="1"
                >按时间排序
              </el-radio>
              <el-radio v-model="from.OrderOfItems" label="2">倒序</el-radio>
            </template>
          </div>
        </div>
        <div class="parameters-list">
          <div class="parameters-list-title">答案位置</div>
          <div class="parameters-list-cnt">
            <template>
              <el-radio v-model="from.answerLocation" label="0">无 </el-radio>
              <el-radio v-model="from.answerLocation" label="1">
                每题之后
              </el-radio>
              <el-radio v-model="from.answerLocation" label="2"
                >所有题之后
              </el-radio>
              <el-radio v-model="from.answerLocation" label="3"
                >独立文件
              </el-radio>
            </template>
          </div>
        </div>
        <div class="parameters-list">
          <div class="parameters-list-title">变式题顺序</div>
          <div class="parameters-list-cnt">
            <template>
              <el-radio v-model="from.sendPriority" label="1">按顺序 </el-radio>
              <el-radio v-model="from.sendPriority" label="2">随机</el-radio>
              <el-radio v-model="from.sendPriority" label="3"
                >自定义顺序
              </el-radio>
            </template>
            <input
              v-model="from.sendList"
              type="text"
              placeholder="例：123"
              class="df-text"
              style="width: 100px"
            />
          </div>
        </div>
        <div class="parameters-list">
          <div class="parameters-list-title">拓展题顺序</div>
          <div class="parameters-list-cnt">
            <template>
              <el-radio v-model="from.expandSendPriority" label="1"
                >按顺序
              </el-radio>
              <el-radio v-model="from.expandSendPriority" label="2"
                >随机</el-radio
              >
              <el-radio v-model="from.expandSendPriority" label="3"
                >自定义顺序
              </el-radio>
            </template>
            <input
              v-model="from.sendListVal"
              type="text"
              placeholder="例：123"
              class="df-text"
              style="width: 100px"
            />
          </div>
        </div>
        <div class="parameters-list" style="height: 117px">
          <div class="parameters-list-title">报告结构自定义</div>
          <div class="parameters-list-cnt">
            <template>
              <el-checkbox-group v-model="from.reportStructure">
                <!-- "cover", "endPaper", "backCover", "area" ,"classNecessary" ,"gradeNecessary" ,"analyse" ,"variantTraining" ,"layeredExpand" -->
                <el-checkbox label="cover">显示封面</el-checkbox>
                <el-checkbox label="endPaper" disabled>显示扉页</el-checkbox>
                <el-checkbox label="backCover" disabled>显示封底</el-checkbox>
                <el-checkbox label="area">显示签字区</el-checkbox>
                <el-checkbox label="classNecessary">显示班级必做题</el-checkbox>
                <el-checkbox label="classDontpushQuestion">
                  班级不做题模块
                </el-checkbox>
                <el-checkbox label="gradeNecessary">显示年级必做题</el-checkbox>
                <el-checkbox label="gradeDontpushQuestion">
                  年级不做题模块
                </el-checkbox>
                <el-checkbox label="analyse">成绩分析各模块</el-checkbox>
                <el-checkbox label="variantTraining">
                  变式题训练模块
                </el-checkbox>
                <el-checkbox label="layeredExpand">拓展题训练模块</el-checkbox>
              </el-checkbox-group>
            </template>
          </div>
        </div>
        <div class="parameters-list" style="height: 69px">
          <div class="parameters-list-title">变式题格式自定义</div>
          <div class="parameters-list-cnt">
            <template>
              <!-- "knowledgePoint", "chapter", "wrongReason", "QRcode" ,"guide" ,"answerArea" ,"analysis"  -->
              <el-checkbox-group v-model="from.variantTrainingList">
                <el-checkbox label="knowledgePoint">显示考点</el-checkbox>
                <el-checkbox label="chapter">显示教材章节</el-checkbox>
                <el-checkbox label="wrongReason">显示错因</el-checkbox>
                <el-checkbox label="qrCode">显示试题二维码</el-checkbox>
                <el-checkbox label="guide">显示思路点拨</el-checkbox>
                <el-checkbox label="answerArea">显示作答区</el-checkbox>
                <el-checkbox label="analysis">显示变式题答案解析</el-checkbox>
                <el-checkbox label="bestStudents">优秀作答</el-checkbox>
              </el-checkbox-group>
            </template>
          </div>
        </div>
        <div class="parameters-list" style="height: 69px">
          <div class="parameters-list-title">拓展题格式自定义</div>
          <div class="parameters-list-cnt">
            <template>
              <el-checkbox-group v-model="from.layeredExpandList">
                <el-checkbox label="knowledgePoint">显示考点</el-checkbox>
                <el-checkbox label="chapter">显示教材章节</el-checkbox>
                <el-checkbox label="wrongReason">显示错因</el-checkbox>
                <el-checkbox label="qrCode">显示试题二维码</el-checkbox>
                <el-checkbox label="guide">显示思路点拨</el-checkbox>
                <el-checkbox label="answerArea">显示作答区</el-checkbox>
                <el-checkbox label="analysis">显示拓展题答案解析</el-checkbox>
                <el-checkbox label="bestStudents">优秀作答</el-checkbox>
              </el-checkbox-group>
            </template>
          </div>
        </div>
        <div class="parameters-list" style="height: 69px">
          <div class="parameters-list-title">原题格式自定义</div>
          <div class="parameters-list-cnt">
            <template>
              <el-checkbox-group v-model="from.theOriginalQuestionList">
                <el-checkbox label="knowledgePoint">显示考点</el-checkbox>
                <el-checkbox label="chapter">显示教材章节</el-checkbox>
                <el-checkbox label="wrongReason">显示错因</el-checkbox>
                <el-checkbox label="qrCode">显示试题二维码</el-checkbox>
                <el-checkbox label="guide">显示思路点拨</el-checkbox>
                <el-checkbox label="answerArea">显示作答区</el-checkbox>
                <el-checkbox label="analysis">显示原题答案解析</el-checkbox>
                <el-checkbox label="bestStudents">优秀作答</el-checkbox>
              </el-checkbox-group>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    wrongOrderRangeData: Object,
  },
  data() {
    return {
      reportType: 0,
      from: {
        // coverTitleOption: "1",
        // coverTitle: "",
        // isShowExamName: "",
        // isShowExamDate: "1",
        // examDate: "1",
        // isShowOriginal: "2",
        // smallQuestionScore: "1",
        // wrongPriority: "1",
        // // 项目顺序
        // OrderOfItems: "1",
        // // 答案位置
        // answerLocation: "1",
        // // 推题顺序
        // sendPriority: "1",
        // sendList: "",
        // reportStructure: [
        //   "封面",
        //   "成绩分析",
        //   "试题",
        //   "错因",
        //   "考点",
        //   "年级必做题",
        //   "班级必做题",
        //   "答案解析",
        //   "成绩分析各模块",
        //   "发布答案时间",
        //   "试题二维码",
        // ],
      },
    };
  },
  created() {
    this.setData();
    // 获取区域列表
  },
  methods: {
    submit() {
      // console.log("进来了");
      if (this.from.coverTitleOption == 2 && this.from.coverTitle == "") {
        this.$message({
          message: "请输入封面标题",
          type: "warning",
        });
        return;
      }
      if (this.from.examDateOptions == 2 && this.from.examDate == "") {
        this.$message({
          message: "请输入选择考试日期",
          type: "warning",
        });
        return;
      }
      if (this.from.sendPriority == 3 && this.from.sendList == "") {
        this.$message({
          message: "请输入推题顺序",
          type: "warning",
        });
        return;
      }
      //匹配掉中文
      // let str = /[\u4e00-\u9fa5]/;
      let arr = [
        "封面",
        "成绩分析",
        "试题",
        "错因",
        "考点",
        "年级必做题",
        "班级必做题",
        "答案解析",
        "成绩分析各模块",
        "发布答案时间",
        "试题二维码",
      ];
      arr.forEach((item) => {
        this.from.reportStructure = this.from.reportStructure.filter(
          (index) => index != item
        );
      });

      // console.log(this.from.reportStructure);
      this.$emit("setAllData", "wrongOrderRangeData", this.from);
    },
    setData() {
      this.from = Object.assign({}, this.wrongOrderRangeData);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrongOrder {
}
</style>
