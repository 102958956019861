<template>
  <div :key="keys" class="ReportParameters">
    <div class="top-header-box">
      <div class="top-style">
        <div class="left-box">
          <div class="span">1</div>
          <div class="title">设置错题定义</div>
          <hr />
          <div class="span" :class="{ unset: indexType < 2 }">2</div>
          <div class="title">设置推送数量和范围</div>
          <template>
            <hr />
            <div class="span" :class="{ unset: indexType < 3 }">3</div>
            <div class="title">设置顺序结构与样式</div>
          </template>
          <template>
            <hr />
            <div class="span" :class="{ unset: indexType < 4 }">4</div>
            <div class="title">详细参数设置</div>
          </template>
        </div>
        <div class="right-box">
          <el-button
            class="btn-style"
            type="success"
            :loading="btnLoading"
            @click="submit()"
            >返回</el-button
          >
          <el-button class="btn-style" type="primary" @click="goLink"
            >完 成</el-button
          >
        </div>
      </div>
    </div>
    <!-- 第四个页面 -->
    <wrongParameter
      v-if="indexType == 4"
      ref="wrongParameter"
      :wrong-parameter-data="wrongParameterData"
      :new-question-orders="questionOrders"
      :class-nums="classNums"
      :level-id="levelId"
      :title-name="allTitle"
      :subject-id="subjectId"
      @setAllData="setAllData"
    ></wrongParameter>
    <!-- 第三个页面 -->
    <wrongOrder
      v-if="indexType == 3"
      ref="wrongOrder"
      :wrong-order-range-data="wrongOrderRangeData"
      @setAllData="setAllData"
    ></wrongOrder>
    <!-- 第二个页面 -->
    <wrongQuantityRange
      v-if="indexType == 2"
      ref="wrongQuantityRange"
      :class-list="classList"
      :wrong-quantity-range-data="wrongQuantityRangeData"
      :subject-list-data="subjectListData"
      @setAllData="setAllData"
    ></wrongQuantityRange>
    <!-- 第一个页面 -->
    <wrongQuestions
      v-if="indexType == 1"
      ref="wrongQuestions"
      :old-title="title"
      :old-intro="intro"
      :wrong-questions-data="wrongQuestionsData"
      @setWrongQuestions="setWrongQuestions"
    ></wrongQuestions>
    <div class="btn-box">
      <el-button
        v-if="indexType != 1"
        class="goback-btn"
        type="info"
        icon="el-icon-d-arrow-left"
        @click="goBack"
        >上一步</el-button
      >
      <el-button
        v-if="indexType != 4"
        type="primary"
        class="btn-all"
        @click="next"
        >保存并进入下一步<i class="el-icon-d-arrow-right el-icon--right"></i
      ></el-button>
      <el-button v-else type="primary" class="btn-all" @click="next"
        >保存<i class="el-icon-d-arrow-right el-icon--right"></i
      ></el-button>
    </div>
  </div>
</template>

<script>
import wrongQuestions from "./components/wrongQuestions.vue";
import wrongQuantityRange from "./components/wrongQuantityRange.vue";
import wrongOrder from "./components/wrongOrder.vue";
import wrongParameter from "./components/wrongParameter.vue";
import { getObj } from "@/core/api/analy/analyreportsubject";
import { getExamQuestionTypeByLevelAndSubject } from "@/core/api/exam/exampaper";
import { subjectOptions } from "@/core/util/constdata";
import { getAnalyTemplate } from "@/core/api/analy/analytemplate";
import { addObj, putObj } from "@/core/api/analy/analytemplate";
export default {
  name: "ReportParameters",
  components: {
    wrongQuestions,
    wrongQuantityRange,
    wrongOrder,
    wrongParameter,
  },
  data() {
    return {
      keys: 1,
      indexType: 1,
      levelId: 0,
      subjectId: 0,
      btnLoading: false,
      classList: [],
      titleList: ["推荐", "完整", "简约"],
      title: "推荐",
      intro: "更聪明地学习、节省时间和创建更出色的学习集",
      subjectListData: [],
      classNums: [],
      //四个页面需要提交的参数
      //第一个页面
      wrongQuestionsData: {
        // 错题定义
        scoreRate: 70,
        simpleDefinition: 70,
        difficultyOption: "0",
        difficultyStart: "",
        questionType: "1",
        //新加参数   指定不推送的内容
        notToPush: "1",
        //推送规则
        pushTheRules: [
          {
            rankType: "1",
            rankTypeVal: 27,
            scoringRateType: "1",
            scoringRateTypeVal: 70,
          },
        ],
      },
      // 第二个页面
      wrongQuantityRangeData: {
        // 推送原题 1 仅推送原题  2 推送变式训练题 默认为2
        pushTheOriginalQuestion: "2",
        pushTheOriginalQuestionClassList: [
          {
            classList: [],
            min: "3",
            max: "3",
            //题目列表 题目name 题目id 题目val
            question: [],
          },
        ],
        // 变式训练题
        proportion: "1",
        // extensionQuestion: "",
        // 分层拓展题推题比例
        layeredExtensionQuestion: "1",
        //排名靠前
        extensionRankingTest: 27,
        //排名靠前推送题类型
        extensionRankingTestType: ["1"],
        //排名靠后
        extensionAfterRanking: 27,
        //排名靠后推送题类型
        extensionAfterRankingType: ["3"],
        // 其他学生推送类型
        extensioNotherRankingType: ["2"],
        // 拓展题班级列表
        extensionQuestionList: [
          {
            classList: [],
            min: "3",
            max: "3",
            //题目列表 题目name 题目id 题目val
            question: [],
          },
        ],
        //提交的班级组装数组
        classPushlist: [
          {
            classList: [],
            min: "3",
            max: "3",
            //题目列表 题目name 题目id 题目val
            question: [],
          },
        ],
      },
      // 第三个页面
      wrongOrderRangeData: {
        coverTitleOption: "1",
        coverTitle: "",
        isShowExamName: "1",
        isShowExamDate: "1",
        examDate: "1",
        isShowOriginal: "2",
        smallQuestionScore: "1",
        // 是否显示学生名字
        showStudentName: "1",
        // 是否显示学生班级
        showStudentClass: "1",
        // 是否显示小题成绩
        showStudentQuestionScore: "1",
        // 是否显示学生成绩概况
        showStudentAnswerGeneralSituation: "2",
        // 答案字号
        AnswerSize: "15",
        // 答案行距
        AnswerSpacing: "1.3",
        // 变式题行距
        VariableLineSpacing: "1.3",
        wrongPriority: "0",
        // 项目顺序
        OrderOfItems: "1",
        // 答案位置
        answerLocation: "1",
        // 推题顺序
        sendPriority: "1",
        sendList: "",
        //拓展题推题顺序
        expandSendPriority: "1",
        sendListVal: "",
        // 变式题格式自定义
        variantTrainingList: [
          "knowledgePoint",
          "chapter",
          "wrongReason",
          "qrCode",
          "guide",
          "answerArea",
          "analysis",
          "bestStudents",
        ],
        // 拓展题格式自定义
        layeredExpandList: [
          "knowledgePoint",
          "chapter",
          "wrongReason",
          "qrCode",
          "guide",
          "answerArea",
          "analysis",
          "bestStudents",
        ],
        //           "endPaper",
        // "backCover",
        // 报告结构自定义
        reportStructure: [
          "cover",
          "area",
          "classNecessary",
          "gradeNecessary",
          "analyse",
          "variantTraining",
          "layeredExpand",
        ],
        // 原题结构自定义
        theOriginalQuestionList: [
          "knowledgePoint",
          "chapter",
          "wrongReason",
          "qrCode",
          "guide",
          "answerArea",
          "analysis",
          "bestStudents",
        ],
      },
      //试题列表
      questionOrders: [],
      // 第四个页面
      wrongParameterData: {
        //封面
        coverMap:
          "https://static.wtjy.com/analy/report/cover/img/7394c7ba93344a57a3f32e5fd52433e7.jpg",
        //扉页
        titlePage: "",
        //封底
        backCover: "",
        //成绩分析模板
        reportStructures: [],
        // 试题二维码
        QRCode: "1",
        // 错因
        wrongCause: "1",
        // 考点
        testSite: "1",
        // 回归教材
        backToTextbook: "",
        // 答案设置
        answerSetting: [1],

        //需要拆分出来的

        // 年级必做题
        gradeQuestions: {
          //为1自动，为2手动
          type: "1",
          //自动推送的数量
          quantity: "1",
          // 个性题位置 1：前 2：后 3：中
          position: "",
          // 个性题为中的位置
          positionNum: "",
          pushQuestionOrder: "1",
          scoringHigh: 70,
          scoringLow: 0,
        },
        //班级必做题
        classQuestions: {
          //为1自动，为2手动
          type: "1",
          quantity: "1",
          position: "",
          positionNum: "",
          pushQuestionOrder: "1",
          scoringHigh: 70,
          scoringLow: 0,
        },
        classMustQuestionNums: [],
        // 年级不做题
        gradeDontpushQuestionNums: [],
        // 班级不做题
        classDontpushQuestionNums: [],
        // gradeDontpushQuestionNums classDontpushQuestionNums
        //签字区域设置
        signatureText: 3,
        resolve: {
          // 答案是否线上发布
          type: "1",
          //1:相对发布 2绝对发布
          releaseType: "1",
          //相对发布的列表
          resolveTime: [{ time: "1", min: 1, max: 3 }],
          //绝对发布的列表
          absolutelyTime: [{ time: "", min: 1, max: 3 }],
        },
        gradeMustQuestionNums: [],
        // 作答区
        regionListSuc: [],
      },
    };
  },
  created() {
    // this.classOption();
    if (this.$route.query.reportIdNum) {
      this.getObjList();
      this.classOption("1");
    } else {
      this.classOption();
      // if (this.$route.query.type == 0) {
      //   this.wrongOrderRangeData.reportStructure.push("作答区");
      //   this.wrongOrderRangeData.reportStructure.push("签字区");
      // }
    }
  },
  methods: {
    getObjList() {
      let data = {
        reportSubjectId: this.$route.query.id,
      };
      getAnalyTemplate(this.$route.query.reportIdNum, data).then((res) => {
        // console.log(res);
        this.templateId = res.data.data.id;
        this.title = res.data.data.name;
        this.intro = res.data.data.description;
        // console.log(this.title);
        // console.log(this.intro);
        // this.from = JSON.parse(res.data.data.setting);
        this.from = eval("(" + res.data.data.setting + ")");
        this.classPushlist = this.from.classPushlist;
        if (this.$route.query.type == 0) {
          if (!this.from.smallQuestionScore) this.from.smallQuestionScore = "1";
          if (!this.from.showStudentName) this.from.showStudentName = "1";
          if (!this.from.showStudentClass) this.from.showStudentClass = "1";
        }
        if (!("coverTitleOption" in this.from)) {
          let _cover = { coverTitleOption: "1" };
          this.from = Object.assign(_cover, this.from);
        }

        if (
          this.from.scoreRateBeforeValue == undefined &&
          (this.$route.query.type == 1 || this.$route.query.type == 2)
        ) {
          this.from.scoreRateBeforeValue = 27;
          this.from.scoreRateEndValue = 27;
          this.from.consolidationOption = "1";
          this.from.sameLevelOption = "1";
          this.from.sameLevelClassList = [];
          this.from.focusOnMaxAmount = 1;
        }
        this.from = JSON.parse(JSON.stringify(this.from));

        for (let i in this.wrongQuestionsData) {
          if (this.from[i] != undefined) {
            this.wrongQuestionsData[i] = this.from[i];
          }
        }
        for (let i in this.wrongQuantityRangeData) {
          if (this.from[i] != undefined) {
            this.wrongQuantityRangeData[i] = this.from[i];
          }
        }
        if (
          typeof this.wrongQuantityRangeData.extensionRankingTestType ==
          "string"
        ) {
          this.wrongQuantityRangeData.extensionRankingTestType = ["1"];

          //排名靠后推送题类型
          this.wrongQuantityRangeData.extensionAfterRankingType = ["3"];
          // 其他学生推送类型
          this.wrongQuantityRangeData.extensioNotherRankingType = ["2"];
        }
        for (let i in this.wrongOrderRangeData) {
          if (this.from[i] != undefined) {
            this.wrongOrderRangeData[i] = this.from[i];
          }
        }
        for (let i in this.wrongParameterData) {
          if (this.from[i] != undefined) {
            this.wrongParameterData[i] = this.from[i];
          }
        }
        // console.log(this.wrongParameterData);
        if (
          !this.wrongParameterData.classQuestions.scoringHigh &&
          this.wrongParameterData.classQuestions.scoringHigh != 0
        ) {
          this.wrongParameterData.classQuestions.scoringHigh = 70;
          this.wrongParameterData.classQuestions.scoringLow = 0;
          this.wrongParameterData.classQuestions.pushQuestionOrder = "1";
        }

        //        scoringHigh: 70,     scoringLow: 0, classQuestions
        this.keys = new Date().getTime();
        // console.log(this.wrongQuestionsData);
        // console.log(this.wrongQuantityRangeData);
        // console.log(this.wrongOrderRangeData);
        // console.log(this.wrongParameterData);
      });
    },
    // 保存当前页面编辑的值
    setWrongQuestions(title, intro, from) {
      this.title = title;
      this.intro = intro;
      this.wrongQuestionsData = from;
      this.indexType++;
    },
    //三个页面保存数据
    setAllData(key, from) {
      this[key] = from;
      // console.log(this.indexType);
      if (this.indexType < 4) {
        this.indexType++;
      } else {
        this.submitFrom();
      }
      if (this.indexType == 4) {
        this.wrongParameterData.reportStructure =
          this.wrongOrderRangeData.reportStructure;
        this.wrongParameterData.layeredExpandList =
          this.wrongOrderRangeData.layeredExpandList;
        this.wrongParameterData.variantTrainingList =
          this.wrongOrderRangeData.variantTrainingList;
      }
    },
    getItem(val) {
      // console.log(this.wrongOrderRangeData.reportStructure);
      if (
        this.wrongOrderRangeData.reportStructure.filter((item) => item == val)
          .length > 0
      ) {
        return true;
      }
      if (
        this.wrongOrderRangeData.layeredExpandList.filter((item) => item == val)
          .length > 0
      ) {
        return true;
      }
      if (
        this.wrongOrderRangeData.variantTrainingList.filter(
          (item) => item == val
        ).length > 0
      ) {
        return true;
      }
    },
    //提交数据
    submitFrom() {
      // wrongQuestionsData wrongQuantityRangeData wrongOrderRangeData wrongParameterData
      let submitData = Object.assign(
        this.wrongQuestionsData,
        this.wrongQuantityRangeData,
        this.wrongOrderRangeData,
        this.wrongParameterData
      );
      //存在就是编辑
      let data = {
        // cover: this.subFromList.coverMap,
        name: this.title,
        description: this.intro,
        setting: JSON.stringify(submitData),
        isDefault: 0,
        type: this.$route.query.type,
        subjectId: this.subjectId,
        reportSubjectId: this.$route.query.id,
      };
      if (this.getItem("cover")) {
        data.cover = this.wrongParameterData.coverMap;
      }
      if (this.$route.query.reportIdNum) {
        data.id = this.$route.query.reportIdNum;
        putObj(data).then(() => {
          this.$message.success("策略修改成功");
          let query = this.$route.query;
          delete query.reportIdNum;
          setTimeout(() => {
            this.$router.push({
              name: "manageList",
              query: query,
            });
          }, 1000);
        });
      } else {
        addObj(data).then(() => {
          this.$message.success("策略保存成功");
          let query = this.$route.query;
          delete query.reportIdNum;
          setTimeout(() => {
            this.$router.push({
              name: "manageList",
              query: query,
            });
          }, 1000);
        });
      }
    },
    getSubjectList(levelId, subjectId) {
      let data = new URLSearchParams();
      data.append("level", levelId);
      data.append("subjectId", subjectId);

      getExamQuestionTypeByLevelAndSubject(data).then((res) => {
        this.subjectListData = res.data.data.resQuestionTypes;
      });
    },
    classOption(val) {
      //判断一下是什么模板
      let typeModel = this.$route.query.typeModel;
      // if(typeModel)
      this.titleList.forEach((i, a) => {
        if (typeModel == a) {
          this.title = i;
        }
      });
      getObj(this.$route.query.id).then((res) => {
        this.classList = res.data.data.classNums.split(",");
        this.classNums = res.data.data.classNums.split(",");
        // console.log(this.classNums);
        this.reportType = res.data.data.reportType;
        this.subjectId = res.data.data.subjectId;
        this.levelId = res.data.data.levelId;
        this.allTitle = res.data.data.reportName;
        this.questionOrders = res.data.data.questionOrders;
        subjectOptions.forEach((i) => {
          if (i.value == res.data.data.subjectId) {
            this.allTitle = this.allTitle + ` (${i.label})`;
          }
        });
        // if (
        //   !this.from.sameLevelClassList ||
        //   this.from.sameLevelClassList.length == 0
        // ) {
        //   this.from.sameLevelClassList = [];
        //   this.from.sameLevelClassList.push(this.classList);
        // }
        this.getSubjectList(res.data.data.levelId, res.data.data.subjectId);
        // 赋值  给班级组默认添加班级
        if (!val) {
          this.wrongQuantityRangeData.classPushlist[0].classList =
            this.classList;
          this.wrongQuantityRangeData.classPushlist[0].question = [];
          this.wrongQuantityRangeData.extensionQuestionList[0].classList =
            this.classList;
          this.wrongQuantityRangeData.extensionQuestionList[0].question = [];
          this.wrongQuantityRangeData.pushTheOriginalQuestionClassList[0].classList =
            this.classList;
          this.wrongQuantityRangeData.pushTheOriginalQuestionClassList[0].question =
            [];
        }
      });
    },
    goLink() {
      if (this.indexType == 4) {
        this.$refs.wrongParameter.submit();
        return;
      } else {
        this.$message({
          message: "请先完成下面四步操作",
          type: "warning",
        });
      }
    },
    submit() {
      this.$router.go(-1);
    },
    next() {
      if (this.indexType == 1) {
        this.$refs.wrongQuestions.submit();
        return;
      }
      if (this.indexType == 2) {
        this.$refs.wrongQuantityRange.submit();
        return;
      }
      if (this.indexType == 3) {
        this.$refs.wrongOrder.submit();
        return;
      }
      if (this.indexType == 4) {
        this.$refs.wrongParameter.submit();
        return;
      }
      // this.indexType++;
    },
    goBack() {
      this.indexType--;
    },
  },
};
</script>
<style lang="scss">
.ReportParameters {
  .el-title {
    margin: 25px 0;
    // width: 600px;
  }
  .parameters-list {
    &::after {
      clear: both;
      content: ".";
      display: block;
      width: 0;
      height: 0;
      visibility: hidden;
    }
    // &::after {
    //   clear: both;
    // }
    &:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .list-cnt-item {
      margin-bottom: 12px;
    }
  }
  .list-icon {
    margin-left: 20px;
    cursor: pointer;
  }
  .item-select {
    width: 64px;
    i {
      height: 20px;
      line-height: 20px;
    }
    .el-input__suffix {
      right: 0;
    }
    input {
      height: 20px;
      line-height: 20px;
      border: none;
      padding: 0;
      padding-right: 19px;
      color: $primary-color;
    }
  }
  .list:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .list {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    padding: 10px;
    position: relative;
    width: 100%;

    .add-box {
      display: inline-block;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.5);

      .el-icon-plus {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .list-title {
      color: rgba(0, 0, 0, 0.5);
    }
    .list-cnt1.list-cnt {
      left: 400px;
    }
    .list-cnt {
      position: absolute;
      left: 208px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);

      .df-text {
        width: 30px;
        border-bottom: 1px solid #999999;
        text-align: center;
      }

      input:focus {
        outline: none;
      }
    }
  }
  .bj-number-box {
    .scol-cnt {
      z-index: 10;
      overflow-x: scroll;
      width: 173px;
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      ///*<!--修改 滑块 -->*/
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.25);
      }
      p {
        white-space: nowrap;
        margin-bottom: 0;
      }
    }
  }

  .number-box {
    /*display: flex;*/
    /*justify-content: space-between;*/
    position: relative;
    min-height: 40px;
    &:after {
      clear: both;
      content: ".";
      display: block;
      width: 0;
      height: 0;
      visibility: hidden;
    }

    .list-title1 {
      position: absolute;
      left: 208px;
    }

    .list-title2 {
      position: absolute;
      left: 400px;
    }

    .df-text {
      width: 30px;
      border-bottom: 1px solid #999999;
      text-align: center;
    }

    input:focus {
      outline: none;
    }

    .number-cnt {
      position: absolute;
      left: 10px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: center;
    }

    .number-cnt1 {
      left: 208px;
    }

    .scol-cnt {
      display: flex;
    }

    .number-cnt2 {
      left: 400px;
      display: flex;
      overflow-x: scroll;
      width: 360px;
      div {
        white-space: nowrap;
      }
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      ///*<!--修改 滑块 -->*/
      &::-webkit-scrollbar-thumb {
        background-color: #2474ed;
      }
    }

    .number-cnt3 {
      left: 800px;
      i {
        cursor: pointer;
      }

      .el-icon-delete {
        cursor: pointer;
        margin-left: 14px;
      }
    }
  }
  .goback-btn {
    background: rgba(0, 0, 0, 0.85);
    border-color: rgba(0, 0, 0, 0.85);
    // width: 200px;
  }
  .btn-box {
    margin: 50px auto;
    display: block;
    width: 100%;
    text-align: center;
    .el-button {
      width: 200px;
    }
  }
  .el-upload-list.el-upload-list--text {
    display: none;
  }
  .model-btn {
    background-color: #2474ed;
    border-color: #2474ed;
  }
  .model-group {
    .el-dialog__body {
      padding: 20px;
      .el-radio-group {
        margin-top: 10px;
      }
      .upload-excle {
        width: 360px;
        margin: 16px auto 0;
      }
      .list {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        padding: 6px 0;
        &:first-child {
          border-top: 1px solid #e0e0e0;
        }
        .list-name {
          white-space: nowrap;
        }
      }
    }
  }
  .is-circle {
    padding: 6px;
  }

  .cle-el {
    color: white;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #2474ed;
    background: #2474ed;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #2474ed;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #2474ed;
    background: #2474ed;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #2474ed;
  }
  .df-text {
    width: 30px;
    border-bottom: 1px solid #999999;
    text-align: center;
  }
  .df-text:focus {
    outline: none;
  }
  .cnt-box {
    width: 1220px;
    margin: 0 auto;

    .parameters-list {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      padding: 10px;
      position: relative;
      width: 100%;
      .hidden-style {
        margin-right: 0;
      }
      .parameters-list-title {
        color: rgba(0, 0, 0, 0.5);
      }
      .parameters-list-cnt {
        &::after {
          clear: both;
          content: ".";
          display: block;
          width: 0;
          height: 0;
          visibility: hidden;
        }
        position: absolute;
        left: 208px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .cnt-list-box {
      width: 788px;
      margin: 0 auto;
    }
    .title {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
      margin: 32px 0;
      font-weight: bold;
    }
  }
}
</style>
<style lang="scss" scoped>
.ReportParameters {
  padding-top: 74px;

  .top-header-box {
    width: 100%;
    position: fixed;
    left: 0;
    top: 74px;
    z-index: 99;
    padding: 16px 0;
    box-shadow: inset 0 -1px 0 0 #dddddd;
    background-color: #ffffff;
    padding-left: calc(100vw - 100%);
    .top-style {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1220px;
      // width: 80vw;
      margin: 0 auto;

      .right-box {
        display: flex;
        align-items: center;

        span {
          color: #000000;
          margin-left: 8px;
        }

        .btn-style {
          margin-left: 36px;
          background-color: #2474ed;
          border-color: #2474ed;
        }
      }
      .left-box {
        display: flex;
        align-items: center;

        .span {
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          margin-right: 8px;
          background-color: #2474ed;
          border-radius: 50%;
          color: #ffffff;
          cursor: pointer;
        }

        .title {
          font-size: 14px;
          font-weight: 500;
        }

        hr {
          margin: 0 16px;
          width: 80px;
          height: 1px;
          background-color: #e0e0e0;
        }
        .unset {
          background-color: #bdbdbd;
        }
      }
    }
  }
}
</style>
