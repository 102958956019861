<template>
  <div class="wrongQuestions">
    <div class="cnt-box">
      <div class="cnt-list-box">
        <el-input
          v-model="title"
          class="el-title"
          placeholder="标题"
        ></el-input>
        <el-input
          v-model="intro"
          type="textarea"
          placeholder="简介"
          maxlength="30"
          show-word-limit
        ></el-input>
        <div class="title">设置错题定义</div>
        <div class="parameters-list">
          <div class="parameters-list-title">错题定义</div>
          <div class="parameters-list-cnt">
            个人得分率小于等于
            <input v-model="from.scoreRate" type="text" class="df-text" />
            %的试题
          </div>
        </div>
        <div class="parameters-list">
          <div class="parameters-list-title">简单题定义</div>
          <div class="parameters-list-cnt">
            年级得分率大于等于
            <input
              v-model="from.simpleDefinition"
              type="text"
              class="df-text"
            />
            %的试题
          </div>
        </div>
        <div class="parameters-list">
          <div class="parameters-list-title">错题难度推送限制</div>
          <div class="parameters-list-cnt">
            <el-radio v-model="from.difficultyOption" label="0"
              >推送全部</el-radio
            >
            <el-radio
              v-model="from.difficultyOption"
              class="hidden-style"
              label="1"
              >仅推送年级得分率大于等于</el-radio
            >
            <input v-model="from.difficultyStart" type="text" class="df-text" />
            %的试题
          </div>
        </div>
        <div class="parameters-list">
          <div class="parameters-list-title">错题类型</div>
          <div class="parameters-list-cnt">
            <template>
              <el-radio v-model="from.questionType" label="1"
                >推送全部</el-radio
              >
              <el-radio v-model="from.questionType" label="2">简单题 </el-radio>
              <el-radio v-model="from.questionType" label="3">粗心题 </el-radio>
              <el-radio v-model="from.questionType" label="4"
                >简单题加粗心题
              </el-radio>
              <el-radio v-model="from.questionType" label="5"
                >重点关注题
              </el-radio>
            </template>
          </div>
        </div>
        <div class="parameters-list">
          <div class="parameters-list-title">指定不推送的内容</div>
          <div class="parameters-list-cnt">
            <template>
              <el-radio v-model="from.notToPush" label="1">启用参数</el-radio>
              <el-radio v-model="from.notToPush" label="2"
                >不启用参数
              </el-radio>
            </template>
          </div>
        </div>
        <div
          v-if="from.notToPush == 1"
          class="parameters-list"
          style="padding: 0"
          :style="{ height: getHeight('pushTheRules') }"
        >
          <div class="parameters-list-title" style="height: 33px"></div>
          <div class="parameters-list-cnt">
            <template v-for="(v, i) in pushTheRules">
              <div :key="i" class="list-cnt-item">
                <el-select
                  v-model="v.rankType"
                  class="item-select"
                  placeholder="请选择"
                >
                  <el-option label="排名前" value="1"> </el-option>
                  <el-option label="排名后" value="2"> </el-option>
                </el-select>
                <input v-model="v.rankTypeVal" type="text" class="df-text" />
                %的学生，不推送年级得分率
                <el-select
                  v-model="v.scoringRateType"
                  class="item-select"
                  placeholder="请选择"
                  style="width: 50px"
                >
                  <el-option label="高于" value="1"> </el-option>
                  <el-option label="低于" value="2"> </el-option>
                </el-select>
                <input
                  v-model="v.scoringRateTypeVal"
                  type="text"
                  class="df-text"
                />%的试题

                <i class="el-icon-plus list-icon" @click="addRank"></i>
                <i class="el-icon-delete list-icon" @click="delRank(i)"></i>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WrongQuestions",
  props: {
    oldTitle: String,
    oldIntro: String,
    wrongQuestionsData: Object,
  },
  data() {
    return {
      title: "",
      intro: "",
      rankList: {
        rankType: "1",
        rankTypeVal: 27,
        scoringRateType: "1",
        scoringRateTypeVal: 70,
      },
      //rankType  排名方式 ，1 班级前 2 年级前   rankTypeVal 排名值    scoringRateType 得分率类型 1 高于 2  低于 ，scoringRateTypeVal 得分率值
      pushTheRules: [
        // {
        //   rankType: "1",
        //   rankTypeVal: 27,
        //   scoringRateType: "1",
        //   scoringRateTypeVal: 70,
        // },
      ],
      worngDiff: "1",
      from: {
        // // 错题定义
        // scoreRate: 70,
        // simpleDefinition: 70,
        // difficultyOption: "1",
        // difficultyStart: "",
        // questionType: "1",
        // //新加参数   指定不推送的内容
        // notToPush: "1",
      },
    };
  },
  created() {
    this.setData();
    // console.log(this.from);
  },
  methods: {
    submit() {
      if (this.from.difficultyOption == 2 && this.from.difficultyStart == "") {
        this.$message({
          message: "请补充完整“错题难度推送限制”值！",
          type: "warning",
        });
        return;
      }
      let type = false;
      this.pushTheRules.map((index) => {
        if (index.rankTypeVal == "" || index.scoringRateTypeVal == "") {
          type = true;
        }
      });
      if (type) {
        this.$message({
          message: "请补充完整“不推送内容”值！",
          type: "warning",
        });
        return;
      }
      let from = this.from;
      from.pushTheRules = this.pushTheRules;
      // console.log(from);
      this.$emit("setWrongQuestions", this.title, this.intro, from);
    },
    setData() {
      this.title = this.oldTitle;
      this.intro = this.oldIntro;
      this.from = this.wrongQuestionsData;
      //指定不推送内容数组
      this.pushTheRules = this.from.pushTheRules;
    },
    getHeight(key) {
      let height = 44 * this[key].length - 10 + "px";
      return height;
    },
    addRank() {
      this.pushTheRules.push(this.rankList);
    },
    delRank(i) {
      if (this.pushTheRules.length == 1) {
        return;
      }
      this.pushTheRules.splice(i, 1);
    },
  },
};
</script>
<style lang="scss">
.wrongQuestions {
}
</style>
